{
  "member.footer.informations": "Find all the information you need in the <a>Urban Campus Guide</a>",
  "member.footer.allRightsReserved": "(C) 2022 All Rights Reserved. This \"Member area\" is built with ❤️ by <a>www.urbancampus.com</a>",
  "error.title": "We can't load this page.",
  "error.body": "We're sorry, there is an error on our side.{linebreak}Try to refresh and if the problem persists, <a>contact support</a>.",
  "error.button": "Back home",
  "error.message": "There seems to be a problem.{linebreak}Don’t worry, it’s not you - it’s us. Sorry about that.",
  "error.submessage": "If the error persists, contact <a>our support team</a> with the following error:",
  "error.actionText": "Refresh the page",
  "notFound.message": "This page you are looking for can‘t be found,{linebreak}you‘re too far away from us, come back!",
  "notFound.action": "Home page",
  "account.email.label": "Email",
  "account.password.label": "Password",
  "account.passwordConfirm.label": "Password (confirm)",
  "resetPassword.form.resetTitle": "Reset your password.",
  "resetPassword.form.createTitle": "Please create your account by entering your password, this will allow you to access your member area from this point onward.",
  "resetPassword.error.title": "Error",
  "resetPassword.error.message": "An error occurred while saving the new password.",
  "resetPassword.error.passwordMatch": "Passwords don't match.",
  "resetPassword.success.message": "Password updated",
  "resetPassword.reset.title": "Your new password is set.",
  "resetPassword.reset.back": "Back to login",
  "resetPassword.button.save": "Save my new password",
  "stay.title": "Stay",
  "rental.title": "Rental",
  "stay.movein": "Move-in: {date}",
  "stay.moveout": "Move-out: {date}",
  "stay.modification.tooLate": "You are out of the 30 days notice period. <a>Contact support</a> if you want to modify your move-out date.",
  "stay.modification.company": "Company residents can't modify their move-out date themselves. <a>Contact support</a> to handle the modification.",
  "modificationCreate.button.text": "Modify move-out date",
  "modificationCreate.modal.title": "New move-out date",
  "modificationCreate.form.currentDate": "At the moment, your stay finishes on the {date}",
  "modificationCreate.form.minDate": "You can pick a date from the {date} onward.",
  "modificationCreate.form.newEndDateLabel": "New move-out date",
  "modificationCreate.form.extensionDuration": "This corresponds to a <strong>{years, plural, =0 {} one {# year} other {# years}}{hasYearMonthAnd, select, coma {, } and { and } other { }}{months, plural, =0 {} one {# month} other {# months}}{hasMonthDayAnd, select, and { and } other { }}{days, plural, =0 {} one {# day} other {# days}} extension</strong>.",
  "modificationCreate.form.terminationDuration": "You will leave <strong>{years, plural, =0 {} one {# year} other {# years}}{hasYearMonthAnd, select, coma {, } and { and } other { }}{months, plural, =0 {} one {# month} other {# months}}{hasMonthDayAnd, select, and { and } other { }}{days, plural, =0 {} one {# day} other {# days}}</strong> earlier than originally.",
  "modificationCreate.form.price": "<strong>{price}</strong> /month",
  "modificationCreate.form.contactSupport": "<a>Contact support</a>",
  "modificationCreate.form.confirmButtonText": "Confirm",
  "modificationCreate.form.thanks": "Thanks {name}!",
  "modificationCreate.form.newEndDate": "<strong>Your stay will now finish on the {date}.</strong>",
  "modificationCreate.form.coupleContracts": "Contracts are on their way to your inbox now. Please sign them as soon as you receive them. Your partner will receive them and need to sign the contracts as well, please let them know!",
  "modificationCreate.form.coupleUpdate": "Once signed, the changes will be applied to you subscription.",
  "modificationCreate.form.closeButton": "Close",
  "modificationCreate.reason.label": "Why?",
  "modificationCreate.details.label": "Any more details?",
  "modificationCreate.form.newParkingEndDateLabel": "New end of rental date",
  "modificationCreate.parkingDetails.label": "Why do you want to finish your rental?",
  "modificationCreate.reason.MOVING_FROM_MADRID": "I am moving away from {city}",
  "modificationCreate.reason.NEED_MORE_SPACE": "I need more space",
  "modificationCreate.reason.NEED_MORE_PRIVACY": "I need more privacy",
  "modificationCreate.reason.FINANCIAL_CHANGED": "My financial situation has changed",
  "modificationCreate.reason.COMMUNITY_DONT_FIT": "The community is not a fit for me",
  "modificationCreate.reason.NOT_ENJOYING_UC": "I am not enjoying living at Urban Campus",
  "modificationCreate.reason.OTHER": "Other (fill in reason below)",
  "modificationCreate.penalty.none": "No penalty will be applied.",
  "modificationCreate.penalty.applied": "Commitment breached: a penalty will be applied.",
  "modificationCreate.contracts.waiting": "Waiting for signature...",
  "modificationCreate.contracts.button": "Sign contracts",
  "modificationCreate.form.update": "The change will be visible soon.",
  "modificationCreate.error": "An error occurred while changing your moveout date.",
  "modificationCancel.text": "Don't want to change date anymore?",
  "modificationCancel.action": "<a>Cancel your modification</a>",
  "modificationCreate.form.newEndDateError": "Select a valid date",
  "onboarding.introduction.title": "Welcome to Urban Campus!",
  "onboarding.introduction.subtitle": "Create your profile to unlock the full community experience.",
  "onboarding.introduction.button": "Let's do it",
  "onboarding.profilePicture.title": "Upload your pic",
  "onboarding.profilePicture.label": "Add a picture",
  "onboarding.profilePicture.preview": "Preview:",
  "onboarding.profilePicture.error": "Error while uploading picture.",
  "onboarding.profilePicture.button": "Continue",
  "onboarding.bio.title": "Let the world know!",
  "onboarding.bio.subtitle": "We'll use this info to generate your bio.",
  "onboarding.bio.past": "Tell us a bit about yourself! What have you been up to during the last 3-5 years?",
  "onboarding.bio.now": "Tell us what you’re up to now",
  "onboarding.bio.passion": "What are you passionate about (interests/hobbies)?",
  "onboarding.bio.expectation": "What are you looking to get out of the community?",
  "onboarding.bio.country": "From",
  "onboarding.bio.nickname": "Name",
  "onboarding.bio.pronouns": "Pronouns",
  "onboarding.bio.customPronouns": "Pronouns",
  "onboarding.bio.pronouns.he": "He/Him",
  "onboarding.bio.pronouns.she": "She/Her",
  "onboarding.bio.pronouns.they": "They/Them",
  "onboarding.bio.pronouns.other": "Other",
  "onboarding.bio.error": "Error while updating your bio.",
  "onboarding.bio.button": "Continue",
  "onboarding.bio.back": "Back",
  "onboarding.social.title": "Take it to the next level",
  "onboarding.social.subtitle": "Let people know how to find you",
  "onboarding.social.languages": "Spoken languages",
  "onboarding.social.numberUsedForWhatsapp": "I use {number} as my whatsapp number",
  "onboarding.social.whatsapp": "Whatsapp",
  "onboarding.social.instagram": "Instagram",
  "onboarding.social.linkedin": "Linkedin",
  "onboarding.social.error": "Error while updating your social information.",
  "onboarding.social.button": "Continue",
  "onboarding.social.back": "Back",
  "onboarding.community.title": "We want our community to be your catalyst!",
  "onboarding.community.subtitle": "Connect, collaborate and create.",
  "onboarding.community.grow": "Grow your network",
  "onboarding.community.find": "Find Support",
  "onboarding.community.meet": "Meet new people",
  "onboarding.community.body": "At UC, you'll find a strong sense of community spirit that unites colivers from across the globe. We hope that our community will help you discover new opportunities, friendships and a personal sense of belonging.",
  "onboarding.community.manifestoLink": "<a>Read about our vision for the UC community</a>",
  "onboarding.community.button": "Check out the community",
  "forgot.toast.success": "Check your emails",
  "forgot.layout.title": "Forgotten password?",
  "forgot.layout.subtitle": "Submit your email address and we will send you a link to reset your password.",
  "forgot.layout.sentTitle": "Reset link sent",
  "forgot.sent.info": "The mail with the link to reset password was sent. Check your mailbox and hit the Reset My Password Link to change your password.{linebreak}{linebreak}If you didn't receive the email with the link to reset your password within the next 10 minutes, it means that the email you just typed is not the email linked to your coliver profile. Please retry with another email.{linebreak}{linebreak}<i>Hint: The email registered on our side should be the one you booked your subscription with.</i>{linebreak}{linebreak}<i>If you didn't book via the coliving platform, you must have received an email in the correct inbox with the subject 🔥 New Coliving digital platform 🔥 from us.</i>{linebreak}<i>If you haven't received any emails, please contact your community developer.</i>",
  "forgot.sent.login": "<a>Go back to login</a>",
  "forgot.form.email": "Email",
  "forgot.form.button": "Send the reset link",
  "orderWarning.confirm.title": "Thank you!",
  "orderWarning.confirm.infos": "Thank you, your payment is currently processing. You will receive an email when it's in success or in error.",
  "orderWarning.secure3ds.paymentFailed": "Your last payment of {amount} charged on your {lastChargeKind} ending with {lastChargeLast4} failed. We will charge you again on your {orderKind} ending with {orderLast4} on {nextChargeDate}.",
  "orderWarning.secure3ds.bankSecurity": "Your last payment couldn't be processed automatically.{linebreak}You need to confirm the payment using an extra authentication.{linebreak}Your bank requires this security measure for your card ending in {orderLast4}.{linebreak}To finalize your payment of {amount}, please click on the button.",
  "orderWarning.secure3ds.contactUs": "Please visit <a1>this page</a1> or send an email to <a2>our support team</a2>.",
  "orderWarning.disputed.period": "for the period {startDate to {endDate}",
  "orderWarning.disputed.disputed": "Your last payment of {amount} {periodPart} couldn’t be processed automatically.{linebreak}The charge was first accepted then disputed by your bank. Contact <a>our support team</a> to finalize the payment.",
  "orderWarning.error.update": "Update your payment method to continue enjoying our coliving spaces.",
  "paymentMethod.form.title": "Update my payment method",
  "modSignature.error.waiting": "<strong>Waiting for some signatures of the contracts sent by email</strong> before {issueDate}.{linebreak}This will ensure your stay is extended until the {newDate}.{linebreak}Check the contracts section below for more information.",
  "invoices.title": "Billing history",
  "invoices.header.status": "Status",
  "invoices.header.date": "Date",
  "invoices.header.amount": "Amount",
  "invoices.header.receipts": "Receipts",
  "invoices.header.invoices": "Invoices",
  "invoices.download.error": "Error: Can't download invoice",
  "contracts.title": "Contracts",
  "contracts.header.status": "Status",
  "contracts.header.date": "Date",
  "contracts.header.type": "Type",
  "contracts.header.contract": "Contract",
  "contracts.type.RENTAL": "Lease",
  "contracts.type.RENTAL_EXTENSION": "Lease amendment",
  "contracts.type.RENTAL_EARLY_TERMINATION": "Lease amendment",
  "contracts.type.SERVICE": "Services",
  "contracts.type.SERVICE_EXTENSION": "Services amendment",
  "contracts.type.SERVICE_EARLY_TERMINATION": "Services amendment",
  "contracts.type.RENTAL_CANCELLATION": "Cancellation",
  "contracts.type.SERVICE_CANCELLATION": " Service cancellation",
  "contracts.download.error": "Error: Can't download contract",
  "login.toast.failed": "Incorrect login or password",
  "login.layout.title": "Welcome back buddy",
  "login.form.email": "Email",
  "login.form.password": "Password",
  "login.form.forgot": "Forgot password",
  "login.form.button": "Login",
  "resident.details.from": "From {country}",
  "resident.stay.arriving": "Arriving in {days} days in {building} {flat}!",
  "resident.stay.alumni": "Alumni",
  "resident.stay.flat": "{building} - {flat}",
  "resident.languages": "Languages",
  "resident.socials": "Socials",
  "resident.whatsapp": "Whatsapp",
  "resident.instagram": "Instagram",
  "resident.linkedin": "Linkedin",
  "resident.public": "People from the community can see your information.",
  "resident.private": "No-one from the community can find you.",
  "resident.edit": "Edit profile",
  "resident.edit.bioSubtitle": "Bio",
  "resident.edit.essentialSubtitle": "The essential",
  "resident.edit.socialSubtitle": "Socials",
  "resident.save": "Save",
  "resident.bio.past": "What I was doing for the last 3-5 years",
  "resident.bio.now": "What I do now",
  "resident.bio.passion": "What I am passionate about",
  "resident.bio.expectation": "What I'm you looking to get out of the community",
  "resident.pronouns.He/Him": "He/Him",
  "resident.pronouns.She/Her": "She/Her",
  "resident.pronouns.They/Them": "They/Them",
  "resident.pronouns.Other": "Other",
  "payOrder.link.expired": "The link has expired.",
  "resident.empty.title": "Fill out your profile",
  "resident.empty.subtitle": "Your profile is empty, get started and unlock the full community experience.",
  "resident.empty.action": "Get started",
  "resident.cta.action": "Edit",
  "resident.cta.bio": "<strong>Your bio is empty</strong>. Update your profile and share your story with the community!",
  "resident.cta.languages": "<strong>Fill in your spoken languages</strong>{linebreak}If people knew, it would be easier for them to strike up a conversation with you!",
  "resident.cta.socials": "<strong>Instagram, Linkedin, Whatsapp</strong>{linebreak}Tell the community how they can get in touch with you.",
  "resident.privatescope.info": "At any moment, you can make your profile private in the settings.",
  "resident.flatmates.title": "In their flat",
  "resident.floormates.title": "On their floor",
  "subscriptions.title": "My subscriptions",
  "paymentMethod.preview.update": "Update my payment method",
  "paymentMethod.preview.title": "My payment method",
  "paymentMethod.preview.mandate": "<strong>SEPA Debits:</strong> <elt>IBAN - {last4}</elt>",
  "toast.paymentMethod.updated": "Your payment method is updated!",
  "paymentMethod.form.noIban": "I don't have IBAN number",
  "paymentMethod.form.name": "Owner Name",
  "paymentMethod.form.email": "Email",
  "paymentMethod.form.acceptance": "By providing your IBAN and confirming this payment, you are authorizing Urban Campus and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
  "paymentMethod.form.card": "I have IBAN number",
  "paymentMethod.form.cancel": "Cancel",
  "paymentMethod.form.button": "Update my payment method",
  "paymentOrder.confirmPayment": "Confirm payment",
  "paymentOrder.form.cancel": "Cancel and go back to previous screen",
  "paymentOrder.form.button": "Confirm payment",
  "paymentOrder.newSource": "Change my payment method",
  "paymentOrder.3ds": "Go back",
  "community.title": "Community",
  "community.subtitle": "At Urban Campus, we are all about creating community. <a>Read our community vision statement.</a>",
  "community.tab.current": "Current ({count})",
  "community.tab.upcoming": "Upcoming ({count})",
  "community.residents.showMore": "Show more",
  "community.search.label": "Search members, flat or unit",
  "community.campus.subtitle": "Coliving {current, plural, =0 {} one {• # member} other {• # members}} {upcoming, plural, =0 {} one {• # upcoming member} other {• # upcoming members}}",
  "community.flatmates.title": "In your flat",
  "community.floormates.title": "On your floor",
  "community.campus.title": "Campuses",
  "community.campus.button": "Explore the Community",
  "community.head.title": "Head of community",
  "header.tabs.subscriptions": "Subscriptions",
  "header.tabs.community": "Community",
  "menu.items.profile": "Profile",
  "menu.items.settings": "Settings",
  "menu.items.help": "Support",
  "menu.items.logout": "Log-out",
  "page.title.login": "Login",
  "page.title.home": "Subscriptions",
  "page.title.subscriptions": "Subscriptions",
  "page.title.subscription": "Subscription",
  "page.title.forgotPassword": "Forgot Password",
  "page.title.resetPassword": "Reset Password",
  "page.title.pay": "Pay",
  "page.title.profile": "Profile",
  "page.title.resident": "Resident",
  "page.title.community": "Community",
  "page.title.campus": "Community - {campus}",
  "page.title.notFound": "404 - Not found",
  "page.title.settings": "Settings",
  "page.title.privacyPolicy": "Privacy policy",
  "page.title.manifesto": "Community manifesto",
  "page.title.help": "Help",
  "settings.title": "Settings",
  "settings.changePassword.button": "Change password",
  "settings.changePassword.title": "Change password",
  "settings.ChangePassword.old": "Old password",
  "settings.ChangePassword.password": "New password",
  "settings.ChangePassword.passwordConfirm": "Confirm new password",
  "settings.ChangePassword.save": "Save",
  "settings.ChangePassword.error": "An error occured while changing your password.",
  "settings.form.phoneLabel": "Phone",
  "settings.form.emailLabel": "Email",
  "settings.form.scopeLabel": "Public profile",
  "settings.form.languageLabel": "Preferred language",
  "settings.form.scopeCaption": "By making your profile private, you will disappear from the community list, making you invisible in the member area.",
  "settings.form.genderLabel": "Gender",
  "settings.form.scope.PRIVATE": "No",
  "settings.form.scope.PUBLIC": "Yes",
  "settings.form.gender.0": "No gender",
  "settings.form.gender.1": "Male",
  "settings.form.gender.2": "Female",
  "settings.form.gender.3": "Prefer not to say",
  "settings.form.gender.4": "Non binary",
  "settings.edit.button": "Edit",
  "settings.save.button": "Save",
  "settings.form.error": "An error occured while editing your settings.",
  "language.en": "English",
  "language.fr": "French",
  "language.es": "Spanish",
  "paymentMethod.update": "Update my payment method",
  "paymentMethod.retry": "Retry now",
  "paymentMethod.finalize": "Finalize my payment",
  "housekeeping.title": "Housekeeping",
  "housekeeping.service.CLEAN": "Private space cleaning",
  "housekeeping.service.LINEN": "Bedlinen & towel provision and cleaning",
  "housekeeping.CLEAN.body": "Get the peace of mind of not having to care about cleaning your room.{linebreak}<a>More info and cleaning schedule</a>",
  "housekeeping.CLEAN.title.ONCE": "Once a month",
  "housekeeping.CLEAN.title.TWICE": "Twice a month",
  "housekeeping.CLEAN.title.EVERY": "Every week",
  "housekeeping.CLEAN.title.DEEP": "Deep weekly cleaning",
  "housekeeping.CLEAN.subtitle.DEEP": "We'll clean your whole space thoroughly: bathroom, bedroom, kitchen & living room.",
  "housekeeping.CLEAN.subtitle.LIGHT": "It also includes a 10 minutes in-and-out weekly cleaning of the living room & kitchen.",
  "housekeeping.CLEAN.included": "Included",
  "housekeeping.LINEN.title.EVERY": "Weekly provision",
  "housekeeping.LINEN.title.TWICE": "Twice a month provision",
  "housekeeping.LINEN.title.OWN": "Use your own",
  "housekeeping.LINEN.included": "Free",
  "housekeeping.LINEN.information": "We provide the bedlinen and towels, we clean them and we make your bed twice a month.",
  "housekeeping.service.current": "Current option:",
  "housekeeping.service.next": "Upcoming option:",
  "housekeeping.service.available": "Available options:",
  "housekeeping.service.currentText": "{frequency} • {servicePrice}",
  "housekeeping.service.currentTextWithDate": "{frequency} • {servicePrice} • Subscribed until {endDate}",
  "housekeeping.service.nextText": "{frequency} • {servicePrice} • Starting on {startDate}",
  "housekeeping.service.nextReadyText": "{frequency} • {servicePrice} • Subscribed until {endDate}",
  "housekeeping.service.change": "Change option",
  "housekeeping.service.changeUpcoming": "Change upcoming option",
  "housekeeping.changes.apply": "Apply changes",
  "cancel.sub.error": "Cannot cancel this subscription",
  "create.sub.error": "Cannot create this subscription",
  "extraServices.title": "Extra-services in your campus",
  "extra.service.fit_class": "Outdoor fitness session",
  "extra.service.yoga_class": "Outdoor yoga session",
  "extra.service.es_class": "Spanish classes",
  "extra.service.en_class": "English classes",
  "extra.service.breakfast": "Breakfast box",
  "extra.service.fruit_vege_basket": "Fruit & vegetable basket",
  "extra.service.storage": "Additional storage unit",
  "extra.service.parking": "Parking spot",
  "extra.service.price": "{servicePrice}/month",
  "extra.service.prices": "From {servicePrice}/month",
  "extra.service.subscribed": "Subscribed",
  "extra.service.subscribe": "Subscribe",
  "extra.service.unsubscribe": "Unsubscribe",
  "week.days.monday": "Monday",
  "week.days.tuesday": "Tuesday",
  "week.days.wednesday": "Wednesday",
  "week.days.thursday": "Thursday",
  "week.days.friday": "Friday",
  "week.days.saturday": "Saturday",
  "week.days.sunday": "Sunday",
  "extra.service.frequencyWeekly": "Every {day} at {time} • {duration}h",
  "extra.service.subscribeAndStart": "Subscribe and start next month.",
  "extra.service.endDetails": "You are subscribed until the {date}.",
  "extra.service.startDetails": "Your subscription starts on the {date}.",
  "extra.service.storage.body": "For those residents who need more space, we have storage units available to rent. These units offer an additional 4 square meters for you to store your items and belongings, in an area separate from your personal living space. Availability limited and upon request.",
  "extra.service.storage.moreInfo": "<a>More information</a>",
  "extra.service.storage.subscribe": "If you are interested in the extra-service or want to cancel it, please click on the following button and we’ll be in touch.",
  "extra.service.storage.callback": "Contact me",
  "extra.service.storage.success": "Thanks for your request, we'll get back to you soon.",
  "extra.service.storage.error": "An error occured while sending your request.",
  "extra.service.parking.body": "Enhance your daily routine by securing a convenient parking spot with our monthly rental options. Say goodbye to the hassle of daily parking searches. We offer two types of parking spaces to suit your needs:",
  "extra.service.parking.prices.parkspot": "• Indoor parking spot: {servicePrice} per month.",
  "extra.service.parking.prices.parkbox": "• Private parking garage: {servicePrice} per month",
  "extra.service.parking.subscribe": "If you are interested in the extra-service or want to cancel it, please click on the following button and we’ll be in touch.",
  "extra.service.parking.callback": "Contact me",
  "extra.service.parking.success": "Thanks for your request, we'll get back to you soon.",
  "extra.service.parking.error": "An error occured while sending your request.",
  "subscription.space.title": "{slug, select, roo {Room} std {Studio} apt1b {1 bedroom apartment} apt2b {2 bedrooms apartment} parkspot {Parking number} parkbox {Box number} other {}} {roomName}",
  "subscription.action.active": "Manage subscription",
  "subscription.state.NO_STATE": "Unknown",
  "subscription.state.CREATED": "Created",
  "subscription.state.CONTRACT_PENDING": "Contract Pending",
  "subscription.state.CONTRACT_SIGNED": "Contract Signed",
  "subscription.state.DEPOSIT_PAID": "Deposit paid",
  "subscription.state.DEPOSIT_ERROR": "Depsoit error",
  "subscription.state.REVIEWING": "Reviewing",
  "subscription.state.REVIEW_FAILED": "Review failed",
  "subscription.state.READY": "Ready",
  "subscription.state.ACTIVE": "Active",
  "subscription.state.PAST_DUE": "Past due",
  "subscription.state.UNPAID": "Unpaid",
  "subscription.state.CANCELING": "Canceling",
  "subscription.state.PAUSED": "Paused",
  "subscription.state.OVER": "Over",
  "subscription.state.DEPOSIT_REFUND": "Deposit refund",
  "subscription.state.CANCELED": "Canceled",
  "community.access.denied": "The community is not accessible for people who left the space since more than a year or for people that didn’t finish their booking process. If you think you should have access to it, contact support.",
  "payment.title": "Payments",
  "payment.rent.title": "Main rent",
  "payment.service.title": "Services & Extra-services",
  "payment.multiple.provider": "Services and extra-services will be charged apart from your main bill. You will see 2 transactions on your bank account.",
  "payment.multiple.explanation": "We are legally required to ask you a second time for a payment method, because these extra-services will be charged apart your main bill. You will see 2 transactions on your bank account and 2 different invoices in your member area.",
  "payment.card.number": "<strong>**** **** **** {last4}</strong> - {date}",
  "payment.bankTransfer.title": "Bank transfer",
  "payment.bankTransfer.comment": "This IBAN number is unique and should not be shared.",
  "payment.bankTransfer.acccountHolder": "Account holder",
  "payment.bankTransfer.bic": "BIC Code",
  "payment.bankTransfer.iban": "IBAN",
  "payment.bankTransfer.country": "Country",
  "payment.bankTransfer.support": "<a>Contact support to change payment method</a>",
  "payment.form.name": "Owner Name",
  "payment.form.error": "Payment method refused",
  "payment.form.success": "Payment method updated",
  "payment.modal.title": "Payment method",
  "payment.modal.serviceTitle": "Payment method for extra-services",
  "payment.preview.update": "Edit",
  "payment.form.button": "Update payment method",
  "payment.form.switch": "{kind, select, SEPA {I don't have an IBAN, <a>fill-in a credit card</a>} CC {I don't have a credit card, <a>fill-in an IBAN</a>} other {}}",
  "payment.form.acceptance": "By providing your IBAN and confirming this payment, you are authorizing Urban Campus and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
  "payment.next.title": "Next payment",
  "payment.next.errorTitle": "Payment in error",
  "payment.next.subtitle": "{amount} • {date}",
  "payment.next.errorSubtitle": "{amount} {last4, select, undefined {} other {• {kind} - {last4}}}",
  "payment.partiallyPaid.title": "{month} payment",
  "payment.partiallyPaid.subtitle": "Remaining amount:  {amountRemaining}",
  "payment.partiallyPaid.contactSupport": "Contact support",
  "privacy.title": "Privacy policy",
  "privacy.lastUpdate": "Last updated: {date}",
  "privacy.intro.1": "ONE THIRD (whose head office is located at 16 rue sainte Apolline, 75002 Paris, France, registered with the Trade and Companies Register of Paris under number 824582480), trading as URBAN CAMPUS (“ONE THIRD”, “URBAN CAMPUS”, “us” and “our”) operates these websites: booking.urbancampus.com and member.urbancampus.com (hereafter together the “Sites”). The Sites are only accessible to users who have created a user account (“User” or “You”).",
  "privacy.intro.2": "The Sites allow Users to book coliving spaces within Urban Campus managed residences and select the related services (the “Services”).",
  "privacy.intro.3": "In order to enhance your browsing experience and the performance of its Services, URBAN CAMPUS requires the collection of some of your personal data. URBAN CAMPUS, as the data controller, attaches great importance to the protection and respect of your privacy.",
  "privacy.intro.4": "This Privacy Policy as well as our <a1>Terms and Conditions</a1> and the <a2>Cookies Policy</a2> apply to browsing the Sites and on the Services purchased by the User. This Privacy Policy gives you details about the manner and purposes for which we collect and use the information that you provide us and that we gather throughout the process.",
  "privacy.intro.5": "The Personal Data collected will be processed in accordance with the provisions of the applicable regulation related to the protection of personal data. We invite you to read this Privacy Policy carefully.",
  "privacy.data.title": "<n>01.</n> What data is collected and used?",
  "privacy.data.intro.1": "By using our Sites and our Services, you are required to provide us with information, some of which can directly or indirectly identify you (“Personal Data”), when creating a User account, purchasing a paid subscription or booking a coliving space on the Sites.",
  "privacy.data.intro.2": "The Personal Data that we collect is the following:",
  "privacy.data.1.title": "<n>1.1</n> Data entered when creating your User account",
  "privacy.data.1.1": "To use our Services, you have to create a User account by entering your Personal Data. This information is then transferred into our CRM tool and is limited to the following data:",
  "privacy.data.1.list.1": "<li>Your first name</li><li>Your last name</li><li>Your email address</li><li>Your password</li><li>Your birthdate</li><li>Your phone number</li><li>Your Address</li>",
  "privacy.data.1.2": "When creating / completing your User profile, you may, on optional basis, provide the following information:",
  "privacy.data.1.list.2": "<li>Your preferred language for email communication</li><li>Your job</li><li>Your interests</li><li>Your country of origin</li><li>The pronouns you identify with</li><li>Your profile picture, bio, instagram, linkedin, whatsapp</li>",
  "privacy.data.2.title": "<n>1.2</n> Data entered when subscribing",
  "privacy.data.2.1": "When you subscribe for our coliving Services (or related), the payment data you provide are collected and used by our service provider, STRIPE, which uses a secure online payment platform (PCI-DSS).",
  "privacy.data.2.2": "For more information about the use of your bank data, you can read article 3 “How your bank data are collected and managed?” of this Privacy Policy.",
  "privacy.data.3.title": "<n>1.3</n> Data collected when using our Services",
  "privacy.data.3.1": "When you book a coliving space through the Sites or use our Services, we collect and use Personal Data concerning you directly or indirectly, such as:",
  "privacy.data.3.list": "<li>The unique account ID assigned when booking your coliving space</li>  <li>The information on your account and profile</li>  <li>Legal documents requested during your account registration</li>  <li>Your guarantor's name and his/her annual salary</li>  <li>Information related to your booking (room, services, duration, changes, contracts of your previous stays);</li>  <li>Payment details (payment used, amount, date of payment, invoice number</li>",
  "privacy.data.4.title": "<n>1.4</n> Data collected by cookies",
  "privacy.data.4.1": "We collect the following browsing data through cookies dropped on our Sites:",
  "privacy.data.4.list": "<li>Your IP address</li><li>The type and version of the browser you are using</li><li>The type of device with which you are browsing the Sites</li><li>The networks from which you connect to the Sites</li><li>The pages of our Sites you visit</li><li>The content you view on the Sites</li><li>The time spent during each visit to the pages and contents of the Sites</li><li>The language in which you are visiting our Sites</li><li>The location (country) you are visiting our Sites from</li>",
  "privacy.data.4.2": "To learn more about our use of cookies click <a>here</a>.",
  "privacy.purpose.title": "<n>02.</n> What are the purpose behind porcessing your data? ",
  "privacy.purpose.1": "Your personal data is intended for and is used by URBAN CAMPUS for:",
  "privacy.purpose.list": "<li>Creating, managing and authenticating your User account on the Sites</li><li>Share your User profile with users of the Sites</li><li>Giving you access to the Services and functionalities available on our Sites</li><li>Allowing you to book and pay for coliving spaces</li><li>Ease the residents’ re-entry and change of room for coliving spaces</li><li>Sending you confirmations regarding subscriptions or booking coliving spaces</li><li>Granting you access to the building concerned by the booking of the coliving space</li><li>Managing your subscriptions to our Sites, sending you payment related notifications and keeping track of invoices</li><li>Sending you notifications and reminders on the steps preceding and following your move-in to your new coliving studio</li><li>Sending you newsletters about the Services of the Sites and what’s new in URBAN CAMPUS, unless you object to it</li><li>Sending you commercial messages on behalf of URBAN CAMPUS or its partners’ if you have previously given your consent to it</li><li>Ensuring compliance with applicable legislation, our General Terms and Conditions and Privacy Policy</li><li>Informing you of changes to the Sites or to the Services we offer</li><li>Carrying out analyses and statistics of the visits to the Sites or the booking of coliving spaces</li><li>Adapting and improving our Sites according to the devices and browsers you use</li><li>Conducting analyses and statistics on the use colivings common areas to improve our spaces</li>",
  "privacy.payment.title": "<n>03.</n> How your payment data are collected and managed?",
  "privacy.payment.1": "In order to ensure the security of the payments you make through the Sites, URBAN CAMPUS uses a service provider, STRIPE, whose payment pages use the TLS protocol, and certified PCI-DSS. The PCI-DSS standard is an international security standard for the protection of the confidentiality and integrity of cardholders’ data (card data and transaction data). We do not retain any personally identifiable information or any financial information such as credit card numbers. Rather, all such information is provided directly to our third-party partner, Stripe, whose use of your personal information is governed by their own privacy policy, which may be viewed at <a>https://stripe.com/us/privacy</a>.",
  "privacy.payment.2": "When you purchase a paid subscription on the Sites by credit card or by direct debit (SEPA), the order system connects in real time with the STRIPE system which collects the bank data you provide and checks them to avoid abuse and fraud. URBAN CAMPUS only keeps on its servers the type, the expiration date and the last 4 digits of your credit card or the last 4 digits of your SEPA number as well as the mandate linking Stripe to your SEPA bank account. Moreover, the complete data of your credit card and SEPA are stored on the STRIPE servers and are not at any time transmitted to the servers of the Sites. STRIPE requests the authorization from the bank and transmits to URBAN CAMPUS a transaction number that allows transactions up to the amount of the authorization.",
  "privacy.payment.3": "Your banking data is saved in order to avoid having to enter them on your next payments. They are registered with STRIPE in a secure way. At any time by accessing your User account, you can view the list of your registered payment methods, and delete any of them whenever you like.",
  "privacy.recipient.title": "<n>04.</n> Who are the recipients of your personal data?",
  "privacy.recipient.1": "Your Personal Data are intended strictly to authorized URBAN CAMPUS employees.",
  "privacy.recipient.2": "URBAN CAMPUS will also transmit your Personal Data to its technical service providers, acting in its name and on its behalf for the purpose of performing the Services offered on the Sites.",
  "privacy.recipient.3": "URBAN CAMPUS will also transmit your Personal Data to the owner of the building in which your coliving space has been booked. This Personal Data is necessary for contracts registry and deposits registry in the IVIMA.",
  "privacy.recipient.4": "Other users of the Sites may also have access to your profile information. By default, your name and apartment number will be displayed to them.",
  "privacy.recipient.5": "URBAN CAMPUS may share your Personal Data with a third-party purchaser of our company’s assets.",
  "privacy.recipient.6": "Your Personal Data may, if necessary, be communicated to empowered authorities only upon presentation of a judicial or administrative requisition and in the cases provided for by the applicable law.",
  "privacy.cookies.title": "<n>05.</n> Cookies",
  "privacy.cookies.1": "URBAN CAMPUS uses cookies and trackers on the Sites. For more information on our use of these technologies, please refer to our <a>cookie policy</a>.",
  "privacy.rights.title": "<n>06.</n> What are your rights?",
  "privacy.rights.1": "In accordance with current legislation, you have the right to have access to, modify or delete your Personal Data as well as the right to limit or to object to their processing. You will also be able to exercise your right to portability to obtain a copy of your Personal Data in a structured, usable and machine-readable format, so that it may be transmitted to a third party, if such request is eligible. ",
  "privacy.rights.2": "For the exercise of these rights, you can send your requests by postal mail to the following address: ONE THIRD, 16 rue Sainte-Apolline, 75002 Paris, France.",
  "privacy.rights.3": "You may at any time access your User account on the Sites to modify and update your Personal Data. You agree to provide us with complete, accurate and up-to-date information. However, we may retain some of your Personal Data, when required by law or when we have a legitimate reason to do so (for example, when we believe that you have committed fraud or violated our Terms and Conditions).",
  "privacy.rights.4": "Anyone whose personal data is collected may set general or specific instructions, regarding the retention, deletion and communication of his or her personal data after his or her death. Specific instructions may be registered with the data controller. General instructions may be registered with a trusted third-party certified by the CNIL. General or specific instructions may be changed or deleted at any time.",
  "privacy.rights.5": "Unless you have not expressly consented to it when creating your User account or if you have subsequently unsubscribed, you can choose not to receive promotional emails from us at any time by clicking on the unsubscribe link at the bottom of the promotional emails we send you.",
  "privacy.duration.title": "<n>07.</n> How long are your personal data kept?",
  "privacy.duration.1": "Your Personal Data is stored on URBAN CAMPUS’s servers and kept for the duration strictly necessary for the use of your account and the performance of the Services that we propose on the Sites.",
  "privacy.duration.2": "In particular, your Personal Data is retained:",
  "privacy.duration.list": "<li>3 years after your last contact with URBAN CAMPUS</li><li>3 years after the end of your last booking of a coliving space, in case you have purchased a paid subscription to the Sites</li>",
  "privacy.duration.3": "Data collected by cookies are kept for a maximum period of 25 months.",
  "privacy.duration.4": "Your bank data will be deleted once the transaction has been completed and archived by Stripe for a maximum of 13 months from the date on which the account is debited for evidence purposes, in case of dispute concerning the payment.",
  "privacy.security.title": "<n>08.</n> What confidentiality and data security measures are in place?",
  "privacy.security.1": "URBAN CAMPUS implements all appropriate measures to ensure the security of your data and to reduce the risk of loss, deterioration or misuse of data.",
  "privacy.security.2": "The password you save when you register allows you to protect your User account from unauthorized access. We would like to draw your attention on the precautionary measures you must take to protect the confidentiality of your password and its use. In particular, we strongly advise you to choose a password containing at least 12 alphanumeric characters (combination of uppercase, lowercase, digits and special characters) to secure your authentication.",
  "privacy.security.3": "You also have the possibility, from the settings of your User account, and at any time, to hide your profile information from other users of the Sites.",
  "privacy.security.4": "You are liable for any use of our Sites and the associated Services by any person using your User account ID and password. You must contact us immediately if you have reasons to believe that your User account ID and password have been misappropriated by a third-party. As a precautionary measure, we advise you to close your account at the end of each session.",
  "privacy.transfer.title": "<n>09.</n> Are the data transferred outside the European Union?",
  "privacy.transfer.1": "Your Personal Data is stored on our servers or the servers of our technical service provider, STRIPE, hosted within the European Union.",
  "privacy.transfer.2": "Your Personal Data may be transferred to servers located outside the European Economic Area (EEA) whose level of protection is not considered adequate by the European authorities. Where applicable, URBAN CAMPUS undertakes to regulate such transfers in accordance with the applicable legislation.",
  "privacy.other.title": "<n>10.</n> What other information do you need to know?",
  "privacy.other.1": "URBAN CAMPUS reserves the right to modify this Privacy Policy at any time. We encourage you to check it regularly. Nevertheless, in case of substantial modification, an information message will be sent to you by email.",
  "privacy.other.2": "Should you require any further information about our management of your Personal Data, please send us an e-mail to: <a>support@urbancampus.com</a>",
  "cookies.title": "Cookies consent",
  "cookies.body": "By clicking “Accept“, you consent to our website's use of cookies. We use cookies for analytics purposes, to give you the most relevant experience. <a>Read more</a>",
  "cookies.accept": "Accept",
  "cookies.refuse": "Refuse",
  "share.title": "Share my Urban Campus profile",
  "share.text": "Here is my Urban Campus profile!",
  "share.copied": "Link copied to clipboard",
  "manifesto.title": "Community: The Heart of our Mission",
  "manifesto.purpose.title": "Our purpose",
  "manifesto.purpose.1": "At Urban Campus, we're passionate about transforming the way you connect and meet new people. Our heartfelt mission is to help you forge friendships and cultivate deep, meaningful connections within our community.",
  "manifesto.purpose.2": "Whether it's discovering a gym partner who motivates you, a coworking buddy who inspires your creativity, or simply connecting with people from all corners of the globe, you never know who you might meet. Our aim is to make your everyday experiences richer and more connected, so you can embrace the simple joys of community.",
  "manifesto.values.title": "Our values",
  "manifesto.values.1.title": "Forge New Connections",
  "manifesto.values.1": "Step beyond your comfort zone to meet new faces and broaden your horizons. We encourage you to actively seek out and embrace opportunities to expand your network and introduce yourself to new people.",
  "manifesto.values.2.title": "Engage Wholeheartedly",
  "manifesto.values.2": "Immerse yourself fully in the coliving experience by participating in events and embracing the communal spirit. Have fun, be open to new experiences, and let yourself truly enjoy the vibrancy of our community.",
  "manifesto.values.3.title": "Be Open-Minded",
  "manifesto.values.3": "With a global community at your doorstep, every individual brings something unique. Approach each person with kindness, an open mind, and a willingness to embrace and celebrate our differences.",
  "manifesto.values.4.title": "Sustainability",
  "manifesto.values.4": "The environment is a key focus for us, and we're committed to reducing our environmental footprint. We strive for sustainability in all we do and invite you to join us. Be mindful of your habits and help us foster a more sustainable community for everyone.",
  "manifesto.button": "Discover the community",
  "list.see.more": "See more",
  "help.title": "Support",
  "help.subtitle": "Have you checked the <a>Urban Campus Guide</a>? You can find there all the information you need to make the best out of your experience and how to resolve the most common questions.",
  "help.form.title": "Contact us",
  "help.form.subjectLabel": "Title",
  "help.form.subjectPlaceholder": "Your request in a few words",
  "help.form.contentLabel": "Message",
  "help.form.contentPlaceholder": "Describe your request with as many details as possible, so that we can help you and put a smile on your face",
  "help.form.button": "Submit request",
  "help.form.upload": "Add a file to your request",
  "help.form.change": "Change file",
  "help.error": "An error occured while submitting your request",
  "help.success": "Thank you! We’ve registered your request. Our Support team will get back to you as soon as possible.",
  "help.support": "Please send us an email to <a>support@urbancampus.com</a>.",
  "paymentError.title": "Payment {count, plural, =0 {} one {error} other {errors}}",
  "paymentError.ERROR": "Your payment of {amount} {last4, select, undefined {} other {charged on your <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} has failed.",
  "paymentError.REQUIRES_ACTION": "Your payment of {amount} {last4, select, undefined {} other {charged on your <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} has failed.",
  "paymentError.UNPAID": "Your payment of {amount} {last4, select, undefined {} other {charged on your <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} has failed.",
  "paymentError.DISPUTED": "Your payment of {amount} {last4, select, undefined {} other {charged on your <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} was disputed by your bank.",
  "paymentError.updatePm": "Please <a>update your payment method</a> so we can try charging you again.",
  "paymentError.disputed": "Please contact our support team to resolve the issue. Each disputed payment is penalized by an extra charge of 50€.",
  "paymentError.PARTIALLY_PAID": "Your {month} payment was only partially done: we received {amountReceived} out of the total {amount}.{linebreak}Please proceed with payment of the remaining {amountRemaining}",
  "payment.retry": "Retry payment",
  "payment.retry.error": "Payment failed",
  "payment.retry.success": "Payment succeed",
  "pending.modification.title": "Contract pending signature",
  "pending.modification.sign.embedded": "Please <a>sign your contracts</a>, so that your end date is changed to {date}.",
  "pending.modification.cancel.embedded": "Don't want to modify your end date anymore? <a>Cancel your modification</a>.",
  "pending.modification.sign.not.embedded": "Please sign the contract we sent you by email, so that your end date is changed to {date}.",
  "pending.modification.cancel.not.embedded": "Don't want to modify your end date anymore? <a>Contact support</a>.",
  "maintenance.title": "Maintenance",
  "maintenance.page.title": "Maintenance ongoing",
  "maintenance.page.body": "This page will be back as soon as possible. Please <a>Contact support</a> to make sure they let you know when the app is back on its feets.{linebreak}{linebreak}Sorry for the incovenience.",
  "maintenance.page.reload": "Reload page",
  "extension.button": "Extend stay",
  "extension.modal.title": "Pick a new end date",
  "termination.button.withEndDate": "Move out sooner",
  "termination.button.withoutEndDate": "Pick move-out date",
  "termination.modal.title": "Pick a new end date"
}