import 'babel-polyfill'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import moment from 'moment-timezone'
import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { hotjar } from 'react-hotjar'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'

import client from './Client'
import { App } from './components/App'
import { SENTRY_DSN, SENTRY_ENV, STRIPE_PUBLIC_KEY, HOTJAR_ID_MEMBER, Storage } from './Config'
import { UserProvider } from './contexts'
import { isCookieConsented } from './libs/cookie'
import { translations, getBrowserLanguage } from './libs/translations'

import 'moment/locale/es'
import 'moment/locale/fr'

if (!STRIPE_PUBLIC_KEY) {
  console.error('Missing Stripe public key')
}

const initHotjar = () => {
  if (HOTJAR_ID_MEMBER) {
    hotjar.initialize(HOTJAR_ID_MEMBER)
  } else {
    console.warn('Hotjar not initialized')
  }
}

if (isCookieConsented()) {
  initHotjar()
}

const sentryKey = process.env.SENTRY_DSN_BOOKING

const integrations = [new BrowserTracing({
  routingInstrumentation: Sentry.reactRouterV6Instrumentation(
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  ),
})]

if (SENTRY_ENV === 'production') {
  integrations.push(new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
  }))
}

if (sentryKey) {
  Sentry.init({
    dsn: SENTRY_DSN,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations,
    beforeSend(event) {
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: {
            email: event?.user?.email ?? undefined,
            name: event?.user?.username ?? undefined,
          },
        })
      }
      return event
    },
    environment: SENTRY_ENV,
  })
} else {
  console.warn('Sentry not initialized')
}

moment.tz.setDefault('UTC')

const Container = () => {
  const browserLang = getBrowserLanguage()
  const [locale, setLocale] = useState(browserLang)
  moment.locale(locale)

  const onLanguageChange = lang => {
    setLocale(lang)
    Storage.setItem('lang', lang)
    moment.locale(lang)
  }

  useEffect(() => {
    if (isCookieConsented()) {
      initHotjar()
    }
  }, [])

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <UserProvider>
            <App onLanguageChange={onLanguageChange} initHotjar={initHotjar} />
          </UserProvider>
        </ApolloProvider>
      </BrowserRouter>
    </IntlProvider>
  )
}

ReactDOM.render(
  <Container />,
  document.getElementById('root'),
)
