{
  "member.footer.informations": "Retrouvez toutes les informations du coliving sur le <a>Urban Campus Guide</a>",
  "member.footer.allRightsReserved": "(C) 2022 All Rights Reserved. This \"Member area\" is built with ❤️ by <a>www.urbancampus.com</a>",
  "error.title": "Impossible d'afficher cette page.",
  "error.body": "Nous sommes désolés, il semblerait qu'il y ait un soucis de notre côté.{linebreak}Essayez d'actualiser la page, et si le problème persiste, <a>contactez l'équipe support</a>.",
  "error.button": "Accueil",
  "error.message": "Oups, nous avons un problème.{linebreak}Pas d'inquiétude, cela n'a rien à voir avec vous - c'est nous.",
  "error.submessage": "Si l'erreur persiste, contactez <a>notre équipe de Customer Support</a> avec l'erreur suivante :",
  "error.actionText": "Actualisez la page",
  "notFound.message": "La page que vous recherchez est introuvable,{linebreak}vous êtes trop loin de nous, revenez !",
  "notFound.action": "Page d'accueil",
  "account.email.label": "Email",
  "account.password.label": "Mot de passe",
  "account.passwordConfirm.label": "Mot de passe (confirmation)",
  "resetPassword.form.resetTitle": "Réinitialiser votre mot de passe",
  "resetPassword.form.createTitle": "Veuillez créer votre compte en saisissant votre mot de passe, cela vous permettra d'accéder à votre member area.",
  "resetPassword.error.title": "Erreur",
  "resetPassword.error.message": "Une erreur s'est produite lors de l'enregistrement du nouveau mot de passe.",
  "resetPassword.reset.title": "Votre nouveau mot de passe est défini.",
  "resetPassword.reset.back": "Retour à la connexion",
  "resetPassword.button.save": "Enregistrer mon nouveau mot de passe",
  "resetPassword.error.passwordMatch": "Les mots de passe ne correspondent pas.",
  "resetPassword.success.message": "Mot de passe a été mis à jour.",
  "stay.title": "Séjour",
  "rental.title": "Location",
  "stay.movein": "Date d'arrivée: {date}",
  "stay.moveout": "Date de départ: {date}",
  "stay.modification.company": "Puisque vous avez un contrat de société, veuillez <a>contacter le support</a> pour modifier votre date de déménagement.",
  "stay.modification.tooLate": "Vous avez dépassé le délai de préavis de 30 jours. <a>Contactez le support</a> si vous souhaitez modifier votre date de départ.",
  "modificationCreate.button.text": "Modifier la date de départ",
  "modificationCreate.modal.title": "Nouvelle date de départ",
  "modificationCreate.form.currentDate": "Actuellement, votre séjour se termine le {date}",
  "modificationCreate.form.minDate": "Vous pouvez choisir une date à partir du {date}.",
  "modificationCreate.form.newEndDateLabel": "Nouvelle date de départ",
  "modificationCreate.form.extensionDuration": "Cela correspond à une extension de <strong>{years, plural, =0 {} one {# an} other {# ans}}{hasYearMonthAnd, select, coma {, } and { et } other { }}{months, plural, =0 {} one {# mois} other {# mois}}{hasMonthDayAnd, select, and { et } other { }}{days, plural, =0 {} one {# jour} other {# jours}}</strong>.",
  "modificationCreate.form.terminationDuration": "Vous partirez <strong>{years, plural, =0 {} one {# an} other {# ans}}{hasYearMonthAnd, select, coma {, } and { et } other { }}{months, plural, =0 {} one {# mois} other {# mois}}{hasMonthDayAnd, select, and { et } other { }}{days, plural, =0 {} one {# jour} other {# jours}}</strong> plus tôt que prévu.",
  "modificationCreate.form.price": "<strong>{price}</strong> /mois",
  "modificationCreate.form.contactSupport": "<a>Contactez l'équipe Customer Support</a>",
  "modificationCreate.form.confirmButtonText": "OK",
  "modificationCreate.form.thanks": "Merci {name}!",
  "modificationCreate.form.newEndDate": "<strong>Votre séjour se terminera le {date}.</strong>",
  "modificationCreate.form.coupleContracts": "Vous recevrez vos contrats à signer sous peu dans vos emails. Veuillez les signer dès que vous les recevez. Votre partenaire va aussi recevoir ces contrats à signer par email, n'hésitez pas a le lui rappeler !",
  "modificationCreate.form.coupleUpdate": "Lorsque vous aurez tous les deux signés, les modifications seront appliquées à votre souscription.",
  "modificationCreate.form.closeButton": "Fermer",
  "modificationCreate.reason.label": "Pourquoi?",
  "modificationCreate.details.label": "Plus de détails",
  "modificationCreate.form.newParkingEndDateLabel": "Nouvelle date de départ",
  "modificationCreate.parkingDetails.label": "Poruquoi mettez-vous fin à votre location?",
  "modificationCreate.reason.MOVING_FROM_MADRID": "Je quitte {city}",
  "modificationCreate.reason.NEED_MORE_SPACE": "J'ai besoin de plus d'espace",
  "modificationCreate.reason.NEED_MORE_PRIVACY": "J'ai besoin de plus d'intimité",
  "modificationCreate.reason.FINANCIAL_CHANGED": "Ma situation financière a changé",
  "modificationCreate.reason.COMMUNITY_DONT_FIT": "La communauté ne me convient pas",
  "modificationCreate.reason.NOT_ENJOYING_UC": "Je n'aime pas vivre chez Urban Campus",
  "modificationCreate.reason.OTHER": "Autre (remplissez la raison ci-dessous)",
  "modificationCreate.penalty.none": "Aucune pénalité ne sera appliquée.",
  "modificationCreate.penalty.applied": "Engagement de durée rompu : une pénalité sera appliquée.",
  "modificationCreate.contracts.waiting": "En attente de signature...",
  "modificationCreate.contracts.button": "Signer les contrats",
  "modificationCreate.form.update": "La modification sera bientôt visible.",
  "modificationCreate.error": "Une erreur s'est produite lors de la modification de votre date de départ.",
  "modificationCancel.text": "Vous ne voulez plus changer de date de départ?",
  "modificationCancel.action": "<a>Annuler la modification</a>",
  "modificationCreate.form.newEndDateError": "Selectionnez une date valide",
  "onboarding.introduction.title": "Bienvenue chez Urban Campus!",
  "onboarding.introduction.subtitle": "Créez votre profil pour profiter pleinement de la communauté.",
  "onboarding.introduction.button": "C'est parti",
  "onboarding.profilePicture.title": "Téléchargez votre photo",
  "onboarding.profilePicture.label": "Ajouter une photo",
  "onboarding.profilePicture.preview": "Aperçu:",
  "onboarding.profilePicture.error": "Erreur lors du téléchargement de la photo",
  "onboarding.profilePicture.button": "Suivant",
  "onboarding.bio.title": "Présentez-vous à la communauté",
  "onboarding.bio.subtitle": "Nous utiliserons ces informations pour générer votre bio",
  "onboarding.bio.past": "Que faisiez-vous ces 3-5 dernières années ?",
  "onboarding.bio.now": "Que faites-vous maintenant ?",
  "onboarding.bio.passion": "Qu'est-ce qui vous passionne (intérêts/hobbies) ?",
  "onboarding.bio.expectation": "Qu'attendez de la communauté ?",
  "onboarding.bio.country": "De",
  "onboarding.bio.nickname": "Surnom",
  "onboarding.bio.pronouns": "Pronoms",
  "onboarding.bio.customPronouns": "Pronoms",
  "onboarding.bio.pronouns.he": "Il",
  "onboarding.bio.pronouns.she": "Elle",
  "onboarding.bio.pronouns.they": "Iel",
  "onboarding.bio.pronouns.other": "Autre",
  "onboarding.bio.error": "Erreur lors de la mise à jour de votre bio.",
  "onboarding.bio.button": "Suivant",
  "onboarding.bio.back": "Retour",
  "onboarding.social.title": "On passe au niveau supérieur",
  "onboarding.social.subtitle": "Dites-nous comment la communauté peut vous joindre",
  "onboarding.social.languages": "Langues parlées",
  "onboarding.social.numberUsedForWhatsapp": "J'utilise {number} comme numéro Whatsapp",
  "onboarding.social.whatsapp": "Whatsapp",
  "onboarding.social.instagram": "Instagram",
  "onboarding.social.linkedin": "Linkedin",
  "onboarding.social.error": "Erreur lors de la mise à jour de vos informations sociales",
  "onboarding.social.button": "Suivant",
  "onboarding.social.back": "Retour",
  "onboarding.community.title": "La communauté est votre catalyseur",
  "onboarding.community.subtitle": "Rencontres, collaborations et créations.",
  "onboarding.community.grow": "Développez votre réseau",
  "onboarding.community.find": "Collaborez",
  "onboarding.community.meet": "Rencontrez de nouvelles personnes",
  "onboarding.community.body": "Chez UC, vous trouverez un esprit collectif qui pousse à essayer de nouvelles choses, à vous impliquer et à vous sentir chez vous. Le meilleur environnement pour grandir personnellement et professionnellement. ",
  "onboarding.community.manifestoLink": "<a>Lire le manifeste en entier</a>",
  "onboarding.community.button": "Découvrez la communauté",
  "forgot.toast.success": "Vérifiez vos emails",
  "forgot.layout.title": "Mot de passe oublié ?",
  "forgot.layout.subtitle": "Soumettez votre adresse email et nous vous enverrons un lien pour réinitialiser votre mot de passe",
  "forgot.layout.sentTitle": "Lien de réinitialisation envoyé",
  "forgot.sent.info": "L'email contenant le lien pour réinitialiser le mot de passe a été envoyé. Vérifiez votre boîte de réception et cliquez sur le lien 'Réinitialiser mon mot de passe' pour changer votre mot de passe.{linebreak}{linebreak}Si vous n'avez pas reçu l'email avec le lien pour réinitialiser votre mot de passe dans les 10 minutes qui suivent, cela signifie que l'email que vous venez de taper n'est pas l'email lié à votre profil. Veuillez réessayer avec un autre email.{linebreak}{linebreak}<i>Si vous n'avez pas réservé via la plateforme coliving, vous devez avoir reçu un email dans la bonne boîte de réception avec le sujet 🔥 Nouvelle plateforme Coliving 🔥 de notre part.</i>{linebreak}<i>Si vous n'avez pas reçu d'email, veuillez contacter notre équipe.</i>",
  "forgot.sent.login": "<a>Retour à la connexion</a>",
  "forgot.form.email": "Email",
  "forgot.form.button": "Envoyez le lien",
  "orderWarning.confirm.title": "Merci!",
  "orderWarning.confirm.infos": "Merci, votre paiement est en cours de traitement. Vous recevrez un email quand il aura été accepté ou refusé par votre banque.",
  "orderWarning.secure3ds.paymentFailed": "Votre dernier paiement de {amount} facturé sur {lastChargeKind} se terminant par {lastChargeLast4} a échoué. Nous vous facturerons à nouveau sur votre {orderKind} se terminant par {orderLast4} le {nextChargeDate}.",
  "orderWarning.secure3ds.bankSecurity": "Votre dernier paiement n'a pas pu être traité automatiquement. {linebreak}Vous devez confirmer le paiement en utilisant une authentification supplémentaire. {linebreak}Votre banque exige cette mesure de sécurité pour votre carte se terminant par {orderLast4}.{linebreak}Pour finaliser votre paiement de {amount}, veuillez cliquer sur le bouton.",
  "orderWarning.secure3ds.contactUs": "Veuillez consulter <a1>cette page</a1> ou envoyer un email à <a2>notre équipe support</a2>.",
  "orderWarning.disputed.period": "pour la période du {startDate} au {endDate}",
  "orderWarning.disputed.disputed": "Votre dernier paiement de {amount} {periodPart} n'a pas pu être traité automatiquement.{linebreak}Le débit a d'abord été accepté puis contesté par votre banque. Contactez <a>notre équipe support</a> pour finaliser le paiement..",
  "orderWarning.error.update": "Mettez à jour votre méthode de paiement pour continuer à profiter de nos espaces.",
  "paymentMethod.form.title": "Modifier ma méthode de paiement",
  "modSignature.error.waiting": "<strong>En attente des signatures de contrats envoyés par email</strong> avant {issueDate}.{linebreak}Ainsi, votre séjour sera prolongé jusqu'au {newDate}.{linebreak}Consultez la section 'Contrats' ci-dessous pour plus d'informations.",
  "invoices.title": "Historique de facturation",
  "invoices.header.status": "Statut",
  "invoices.header.date": "Date",
  "invoices.header.amount": "Montant",
  "invoices.header.receipts": "Reçus",
  "invoices.header.invoices": "Factures",
  "invoices.download.error": "Erreur : Impossible de télécharger les factures",
  "contracts.title": "Contrats",
  "contracts.header.status": "Statut",
  "contracts.header.date": "Date",
  "contracts.header.type": "Type",
  "contracts.header.contract": "Contrat",
  "contracts.type.RENTAL": "Contrat de location",
  "contracts.type.RENTAL_EXTENSION": "Modification de bail",
  "contracts.type.RENTAL_EARLY_TERMINATION": "Modification de bail",
  "contracts.type.SERVICE": "Contrat de services",
  "contracts.type.SERVICE_EXTENSION": "Modification du contrat de services",
  "contracts.type.SERVICE_EARLY_TERMINATION": "Modification du contrat de services",
  "contracts.type.RENTAL_CANCELLATION": "Annulation",
  "contracts.type.SERVICE_CANCELLATION": "Annulation de service",
  "contracts.download.error": "Erreur : Impossible de télécharger les contrats",
  "login.toast.failed": "Identifiant ou mot de passe incorrect",
  "login.layout.title": "Bienvenue",
  "login.form.email": "Email",
  "login.form.password": "Mot de passe",
  "login.form.forgot": "Mot de passe oublié",
  "login.form.button": "Connexion",
  "resident.details.from": "De {country}",
  "resident.stay.arriving": "Arrive dans {days} jours à {building} {flat} !",
  "resident.stay.alumni": "Alumni",
  "resident.stay.flat": "{building} - {flat}",
  "resident.languages": "Langues",
  "resident.socials": "Reseau Sociaux",
  "resident.whatsapp": "Whatsapp",
  "resident.instagram": "Instagram",
  "resident.linkedin": "Linkedin",
  "resident.public": "Les personnes de la communauté peuvent voir vos informations.",
  "resident.private": "Les personnes de la communauté ne peuvent pas vous trouver.",
  "resident.edit": "Modifier le profil",
  "resident.edit.bioSubtitle": "Bio",
  "resident.edit.essentialSubtitle": "Général",
  "resident.edit.socialSubtitle": "Social",
  "resident.save": "Sauvegarder",
  "payOrder.link.expired": "Le lien a expiré.",
  "resident.empty.title": "Remplissez votre profil",
  "resident.empty.subtitle": "Votre profil est vide, remplissez-le pour profiter de ce que la communauté a à vous offrir",
  "resident.empty.action": "Commencez",
  "resident.cta.action": "Editer",
  "resident.cta.bio": "<strong>Votre bio est vide</strong>. Mettez à jour votre profil et partagez votre histoire avec la communauté !",
  "resident.cta.languages": "<strong>Indiquez les langues que vous parlez</strong>{linebreak}Il sera plus facile pour les gens de la communauté d'engager la conversation avec vous !",
  "resident.cta.socials": "<strong>Instagram, Linkedin, Whatsapp</strong>{linebreak}Indiquez à la communauté comment elle peut entrer en contact avec vous.",
  "resident.privatescope.info": "À tout moment, vous pouvez rendre votre profil privé dans les paramètres.",
  "resident.bio.past": "Ce que je faisais au cours des 3 à 5 dernières années",
  "resident.bio.now": "Ce que je fais maintenant",
  "resident.bio.passion": "Ce qui me passionne",
  "resident.bio.expectation": "Ce que j'attends de la communauté",
  "resident.pronouns.He/Him": "Il/Lui",
  "resident.pronouns.She/Her": "Elle/Elle",
  "resident.pronouns.They/Them": "Iel/Iel",
  "resident.pronouns.Other": "Autre",
  "resident.flatmates.title": "Dans son appartement",
  "resident.floormates.title": "À son étage",
  "subscriptions.title": "Mes souscriptions",
  "paymentMethod.preview.update": "Modifier ma méthode de paiement",
  "paymentMethod.preview.title": "Ma méthode de paiement",
  "paymentMethod.preview.mandate": "<strong>Prélévements SEPA:</strong> <elt>IBAN - {last4}</elt>",
  "toast.paymentMethod.updated": "Votre méthode de paiement est mise à jour !",
  "paymentMethod.form.noIban": "Je n'ai pas de numéro IBAN",
  "paymentMethod.form.name": "Nom du titulaire du compte",
  "paymentMethod.form.email": "Email",
  "paymentMethod.form.acceptance": "En fournissant votre IBAN et en confirmant ce paiement, vous autorisez Urban Campus et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et votre banque à débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de la part de votre banque selon les termes et conditions de votre contrat avec celle-ci. Le remboursement doit être demandé dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité.",
  "paymentMethod.form.card": "J'ai un numéro IBAN",
  "paymentMethod.form.cancel": "Annuler",
  "paymentMethod.form.button": "Modifier ma méthode de paiement",
  "paymentOrder.confirmPayment": "Confirmer le paiement",
  "paymentOrder.form.cancel": "Annuler et revenir à l'écran précédent",
  "paymentOrder.form.button": "Confirmation du paiement",
  "paymentOrder.newSource": "Changer ma méthode de paiement",
  "paymentOrder.3ds": "Retour",
  "community.title": "Communauté",
  "community.subtitle": "La communauté Urban Campus a pour but de rassembler les gens. <a>Lire notre manifeste.</a>",
  "community.tab.current": "Actuel ({count})",
  "community.tab.upcoming": "A venir ({count})",
  "community.residents.showMore": "Voir plus",
  "community.search.label": "Rechercher des membres, un appartement ou une chambre",
  "community.campus.subtitle": "Coliving {current, plural, =0 {} one {• # membre} other {• # membres}} {upcoming, plural, =0 {} one {• # membre à venir} other {• # membres à venir}}",
  "community.flatmates.title": "Dans votre appartement",
  "community.floormates.title": "À votre étage",
  "community.campus.title": "Campus",
  "community.campus.button": "Voir la communauté",
  "community.head.title": "Responsable de la communauté",
  "header.tabs.subscriptions": "Souscriptions",
  "header.tabs.community": "Communauté",
  "menu.items.profile": "Profil",
  "menu.items.settings": "Paramètres",
  "menu.items.help": "Support",
  "menu.items.logout": "Déconnexion",
  "page.title.login": "Connexion",
  "page.title.home": "Souscriptions",
  "page.title.subscriptions": "Souscriptions",
  "page.title.subscription": "Souscription",
  "page.title.forgotPassword": "Mot de passe oublié",
  "page.title.resetPassword": "Réinitialiser le mot de passe",
  "page.title.pay": "Paiement",
  "page.title.profile": "Profil",
  "page.title.resident": "Résident",
  "page.title.community": "Communauté",
  "page.title.campus": "Communauté - {campus}",
  "page.title.notFound": "404 - Page introuvable",
  "page.title.settings": "Paramètres",
  "page.title.privacyPolicy": "Politique de confidentialité",
  "page.title.manifesto": "Manifeste",
  "page.title.help": "Aide",
  "settings.title": "Paramètres",
  "settings.changePassword.button": "Modifier le mot de passe",
  "settings.changePassword.title": "Modifier le mot de passe",
  "settings.ChangePassword.old": "Ancien mot de passe",
  "settings.ChangePassword.password": "Nouveau mot de passe",
  "settings.ChangePassword.passwordConfirm": "Confirmer le nouveau mot de passe",
  "settings.ChangePassword.save": "Sauvegarder",
  "settings.ChangePassword.error": "Une erreur s'est produite lors de la modification de votre mot de passe.",
  "settings.form.phoneLabel": "Téléphone",
  "settings.form.emailLabel": "Email",
  "settings.form.scopeLabel": "Profil public",
  "settings.form.languageLabel": "Langue préférée",
  "settings.form.scopeCaption": "En rendant votre profil privé, vous disparaîtrez de la liste de la communauté, ce qui vous rendra invisible dans la member area.",
  "settings.form.genderLabel": "Genre",
  "settings.form.scope.PRIVATE": "Non",
  "settings.form.scope.PUBLIC": "Oui",
  "settings.form.gender.0": "Non renseigné",
  "settings.form.gender.1": "Homme",
  "settings.form.gender.2": "Femme",
  "settings.form.gender.3": "Ne se prononce pas",
  "settings.form.gender.4": "Non binaire",
  "settings.edit.button": "Editer",
  "settings.save.button": "Enregistrer",
  "settings.form.error": "Une erreur s'est produite lors de la modification de vos paramètres.",
  "language.en": "Anglais",
  "language.fr": "Français",
  "language.es": "Espagnol",
  "paymentMethod.update": "Actualiser ma méthode de paiement",
  "paymentMethod.retry": "Réessayer maintenant",
  "paymentMethod.finalize": "Finaliser mon paiement",
  "housekeeping.title": "Entretien",
  "housekeeping.service.CLEAN": "Nettoyage des espaces privés",
  "housekeeping.service.LINEN": "Fourniture et nettoyage de linge de lit et de serviettes",
  "housekeeping.CLEAN.body": "Ne vous souciez pas du nettoyage de votre chambre.{linebreak}<a>Plus d'info et programme du nettoyage</a>",
  "housekeeping.CLEAN.title.ONCE": "une fois par mois",
  "housekeeping.CLEAN.title.TWICE": "Deux fois par mois",
  "housekeeping.CLEAN.title.EVERY": "Toutes les semaines",
  "housekeeping.CLEAN.title.DEEP": "Nettoyage en profondeur chaque semaine",
  "housekeeping.CLEAN.subtitle.DEEP": "Nettoyage en profondeur de tout votre espace: sanitaires, chambre, cuisine et salon.",
  "housekeeping.CLEAN.subtitle.LIGHT": "Comprend également un nettoyage hebdomadaire de 10 minutes du salon et de la cuisine.",
  "housekeeping.CLEAN.included": "Inclus",
  "housekeeping.LINEN.title.EVERY": "Toutes les semaines",
  "housekeeping.LINEN.title.TWICE": "Deux fois par mois",
  "housekeeping.LINEN.title.OWN": " Utiliser les vôtres",
  "housekeeping.LINEN.included": "Gratuit",
  "housekeeping.LINEN.information": "Nous fournissons le linge de lit et les serviettes, nous les nettoyons et nous faisons votre lit deux fois par mois.",
  "housekeeping.service.current": "Option actuelle:",
  "housekeeping.service.next": "Prochaine option:",
  "housekeeping.service.available": "Options disponibles:",
  "housekeeping.service.currentText": "{frequency} • {servicePrice}",
  "housekeeping.service.currentTextWithDate": "{frequency} • {servicePrice} • Souscrit jusqu'au {endDate}",
  "housekeeping.service.nextText": "{frequency} • {servicePrice} • Commence le {startDate}",
  "housekeeping.service.nextReadyText": "{frequency} • {servicePrice} • Souscrit jusqu'au {endDate}",
  "housekeeping.service.change": "Changer d’option",
  "housekeeping.service.changeUpcoming": "Changer la prochaine option",
  "housekeeping.changes.apply": "Appliquer les changements",
  "cancel.sub.error": "Une erreur s'est produite lors de l'annulation de votre souscription",
  "create.sub.error": "Une erreur s'est produite lors de la création de votre souscription",
  "extraServices.title": "Extra-services dans votre campus",
  "extra.service.fit_class": "Séance de fitness en plein air",
  "extra.service.yoga_class": "Séance de yoga en plein air",
  "extra.service.es_class": "Cours d'espagnol",
  "extra.service.en_class": "Cours d'anglais",
  "extra.service.breakfast": "Panier petit-déjeuner",
  "extra.service.fruit_vege_basket": "Panier de fruits & légumes",
  "extra.service.storage": "Espace de stockage supplémentaire",
  "extra.service.parking": "Parking",
  "extra.service.price": "{servicePrice}/mois",
  "extra.service.prices": "À partir de {servicePrice}/mois",
  "extra.service.subscribed": "Abonné",
  "extra.service.subscribe": "S'abonner",
  "extra.service.unsubscribe": "Se désabonner",
  "week.days.monday": "Lundi",
  "week.days.tuesday": "Mardi",
  "week.days.wednesday": "Mercredi",
  "week.days.thursday": "Jeudi",
  "week.days.friday": "Vendredi",
  "week.days.saturday": "Samedi",
  "week.days.sunday": "Dimanche",
  "extra.service.frequencyWeekly": "Tous les {day} à {time} • {duration}h",
  "extra.service.subscribeAndStart": "Abonnez-vous et commencez le mois prochain.",
  "extra.service.endDetails": "Vous êtes abonnez jusqu’au {date}.",
  "extra.service.startDetails": "Votre abonnement commence le {date}.",
  "extra.service.storage.body": "Pour les résidents qui ont besoin de plus d’espace, nous disposons d'espaces de stockage disponibles à la location. Ces espaces offrent 4 mètres carrés supplémentaires pour vous permettre de stocker vos objets et effets personnels, dans un espace séparé de votre espace de vie personnel. Disponibilité limitée et sur demande.",
  "extra.service.storage.moreInfo": "<a>Plus d'informations</a>",
  "extra.service.storage.subscribe": "Si vous êtes intéressé par cet extra-service, ou si vous voulez l’arrêter, cliquez sur le bouton suivant et nous vous contacterons.",
  "extra.service.storage.callback": "Contactez-moi",
  "extra.service.storage.success": "Merci, nous vous contacterons bientôt.",
  "extra.service.storage.error": "Une erreur s'est produite lors de l'envoi de votre demande.",
  "extra.service.parking.body": "Améliorez votre quotidien en choisissant une de nos options de location mensuelle de parking. Nous proposons deux types de places de parking pour répondre à vos besoins :",
  "extra.service.parking.prices.parkspot": "• Place de parking extérieur : {servicePrice} par mois",
  "extra.service.parking.prices.parkbox": "• Place de parking en garage : {servicePrice} par mois",
  "extra.service.parking.subscribe": "Si vous êtes intéressé par cet extra-service, ou si vous voulez l’arrêter, cliquez sur le bouton suivant et nous vous contacterons.",
  "extra.service.parking.callback": "Contactez-moi",
  "extra.service.parking.success": "Merci, nous vous contacterons bientôt.",
  "extra.service.parking.error": "Une erreur s'est produite lors de l'envoi de votre demande.",
  "subscription.space.title": "{slug, select, roo {Chambre} std {Studio} apt1b {Appartement 2 pièces} apt2b {Appartement 3 pièces} parkbox {Box numéro} parkspot {Parking numéro}  other {}} {roomName}",
  "subscription.action.active": "Gérer la souscription",
  "subscription.state.NO_STATE": "Inconnu",
  "subscription.state.CREATED": "Créée",
  "subscription.state.CONTRACT_PENDING": "Contrat en attente de signature",
  "subscription.state.CONTRACT_SIGNED": "Contract signé",
  "subscription.state.DEPOSIT_PAID": "Caution payée",
  "subscription.state.DEPOSIT_ERROR": "Erreur de caution",
  "subscription.state.REVIEWING": "En révision",
  "subscription.state.REVIEW_FAILED": "Révision échouée",
  "subscription.state.READY": "Prête",
  "subscription.state.ACTIVE": "Active",
  "subscription.state.PAST_DUE": "En retard de paiement",
  "subscription.state.UNPAID": "Impayée",
  "subscription.state.CANCELING": "En cours d'annulation",
  "subscription.state.PAUSED": "Interrompue",
  "subscription.state.OVER": "Terminée",
  "subscription.state.DEPOSIT_REFUND": "Caution remboursée",
  "subscription.state.CANCELED": "Annulée",
  "community.access.denied": "La communauté n'est pas accessible aux personnes qui ont quitté l'espace depuis plus d'un an ou aux personnes qui n'ont pas terminé leur processus de réservation. Si vous pensez que vous devriez y avoir accès, contactez le support.",
  "payment.title": "Paiements",
  "payment.rent.title": "Location principale",
  "payment.service.title": "Services & Extra-services",
  "payment.multiple.provider": "Les services et extra-services seront facturés en dehors de votre facture principale. Vous verrez 2 transactions sur votre compte bancaire.",
  "payment.multiple.explanation": "Nous sommes légalement tenus de vous demander une deuxième fois un mode de paiement, car ces services supplémentaires seront facturés en sus de votre facture principale. Vous verrez 2 transactions sur votre compte bancaire et 2 factures différentes dans votre espace membre.",
  "payment.card.number": "<strong>**** **** **** {last4}</strong> - {date}",
  "payment.bankTransfer.title": "Virement bancaire",
  "payment.bankTransfer.comment": "Cet IBAN est unique et ne doit pas être communiqué.",
  "payment.bankTransfer.acccountHolder": "Titulaire du compte",
  "payment.bankTransfer.bic": "Code BIC",
  "payment.bankTransfer.iban": "IBAN",
  "payment.bankTransfer.country": "Pays",
  "payment.bankTransfer.support": "<a>Contactez le support pour changer de mode de paiement</a>",
  "payment.form.name": "Nom du titulaire",
  "payment.modal.title": "Méthode de paiement",
  "payment.modal.serviceTitle": "Méthode de paiment pour les extra services",
  "payment.form.error": "Méthode de paiement refusée",
  "payment.form.success": "Méthode de paiement modifiée",
  "payment.preview.update": "Changer",
  "payment.form.button": "Modifier la méthode de paiement",
  "payment.form.switch": "{kind, select, SEPA {Je n'ai pas d'IBAN, <a>renseigner une carte de crédit</a>} CC {Je n'ai pas de carte de crédit, <a>renseigner un IBAN</a>} other {}}",
  "payment.form.acceptance": "En fournissant votre IBAN et en confirmant ce paiement, vous autorisez Urban Campus et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément à ces instructions. Vous bénéficiez d’un droit à remboursement par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte.",
  "payment.next.title": "Prochain paiement:",
  "payment.next.errorTitle": "Paiement en erreur",
  "payment.next.subtitle": "{amount} • {date}",
  "payment.next.errorSubtitle": "{amount} {last4, select, undefined {} other {• {kind} - {last4}}}",
  "payment.partiallyPaid.title": "Paiement pour {month}",
  "payment.partiallyPaid.subtitle": "Total restant à payer:  {amountRemaining}",
  "payment.partiallyPaid.contactSupport": "Contacter le support",
  "privacy.title": "Politique de confidentialité",
  "privacy.lastUpdate": "Dernière mise à jour : {date}",
  "privacy.intro.1": "ONE THIRD (dont le siège social est localisé au 16 rue sainte Apolline, 75002 Paris, France, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 824582480), en tant que URBAN CAMPUS (“ONE THIRD”, “URBAN CAMPUS”, “nous” et “notre”) exploite ces sites web : booking.urbancampus.com et member.urbancampus.com (ci-après ensembles les “Sites”). les Sites sont uniquement accessibles aux utilisateurs qui ont créé un compte utilisateur (« Utilisateur » ou « vous »).",
  "privacy.intro.2": "Les Sites permettent aux Utilisateurs de réserver des espaces de coliving dans les résidences gérées par Urban Campus et de sélectionner les services associés (les “Services“).",
  "privacy.intro.3": "Afin d'améliorer votre expérience de navigation et la performance de ses Services, URBAN CAMPUS nécessite la collecte de certaines de vos données personnelles. URBAN CAMPUS, en tant que responsable du traitement, attache une grande importance à la protection et au respect de votre vie privée.",
  "privacy.intro.4": "La présente Politique de confidentialité ainsi que nos <a1>Conditions Générales d’Utilisation</a1> et la <a2>Politique Cookies</a2> s'appliquent à la navigation sur les Sites et sur les Services souscrits par l'Utilisateur. Cette politique de Confidentialité vous donne des détails sur les moyens et les finalités pour lesquels nous recueillons et utilisons les informations que vous nous fournissez et que nous recueillons tout au long du processus.",
  "privacy.intro.5": "Les données personnelles collectées seront traitées conformément aux dispositions de la réglementation applicable en matière de protection des données personnelles. Nous vous invitons à lire attentivement la présente Politique de Confidentialité.",
  "privacy.data.title": "<n>01.</n> Quelles données sont collectées et utilisées ?",
  "privacy.data.intro.1": "En utilisant les Sites et nos Services, vous êtes amené à nous fournir des informations, dont certaines peuvent directement ou indirectement vous identifier (“Données Personnelles“), lors de la création d'un compte Utilisateur, de la souscription à un abonnement payant ou de la réservation d'un espace de coliving sur les Sites.",
  "privacy.data.intro.2": "Les Données Personnelles que nous collectons sont les suivantes :",
  "privacy.data.1.title": "<n>1.1</n> Données saisies lors de la création de votre compte Utilisateur",
  "privacy.data.1.1": "Pour utiliser nos Services, vous devez créer un compte Utilisateur en saisissant vos Données Personnelles. Ces informations sont ensuite transférées dans notre outil CRM (outil de gestion de la relation client) et se limitent aux données suivantes :",
  "privacy.data.1.list.1": "<li>Votre prénom</li><li>Votre nom</li><li>Votre adresse email</li><li>Votre mot de passe</li><li>Votre date de naissance</li><li>Votre numéro de téléphone</li><li>Votre adresse</li>",
  "privacy.data.1.2": "Lorsque vous créez / complétez votre profil Utilisateur, vous pouvez, à titre facultatif, fournir les informations suivantes :",
  "privacy.data.1.list.2": "<li>Votre langue favorite pour les communications par email</li><li>Votre emploi</li><li>Vos centres d’intérêts</li><li>Votre pays d’origine</li><li>Les pronoms auxquels vous vous identifiez</li><li>Votre photo de profil, bio, Instagram, Linkedin, WhatsApp</li>",
  "privacy.data.2.title": "<n>1.2</n> Données saisies lorsque vous vous abonnez",
  "privacy.data.2.1": "Lorsque vous souscrivez à nos Services de coliving (ou apparentés), les données de paiement que vous fournissez sont collectées et utilisées par notre prestataire de services, STRIPE, qui utilise une plateforme de paiement en ligne sécurisée (PCI-DSS).",
  "privacy.data.2.2": "Pour plus d'informations sur l'utilisation de vos données bancaires, vous pouvez lire l'article 3 “Comment vos données bancaires sont-elles collectées et traitées ?“ de la présente Politique de Confidentialité.",
  "privacy.data.3.title": "<n>1.3</n> Données collectées lorsque vous utilisez nos Services",
  "privacy.data.3.1": "Lorsque vous réservez un espace de coliving via les Sites ou utilisez nos Services, nous collectons et utilisons des Données Personnelles vous identifiant directement ou indirectement telles que :",
  "privacy.data.3.list": "<li>L'identifiant unique du compte attribué lors de la réservation de votre espace de coliving</li><li>Les informations sur votre compte et profil</li><li>Documents juridiques demandés lors de la création de votre compte</li><li>Le nom et le salaire annuel de votre garant</li><li>Informations relatives à votre réservation (chambre, services, durée, modifications, contrats de vos précédents séjours)</li><li>Détails de paiement (moyen de paiement utilisé, montant, date de paiement, numéro de facture)</li>",
  "privacy.data.4.title": "<n>1.4</n> Données collectées par les cookies",
  "privacy.data.4.1": "Nous collectons les données de navigation suivantes via les cookies déposés sur les Sites :",
  "privacy.data.4.list": "<li>Votre adresse IP</li><li>Le type et la version du navigateur que vous utilisez</li><li>Le type de terminal avec lequel vous naviguez sur les Sites</li><li>Les réseaux à partir desquels vous vous connectez aux Sites</li><li>Les pages des Sites que vous visitez</li><li>Le contenu que vous consultez sur les Sites</li><li>Le temps passé lors vos visites sur chacune des pages et contenus des Sites</li><li>La langue dans laquelle vous utilisez les Sites</li><li>Le lieu (pays) à partir duquel vous visitez les Sites</li>",
  "privacy.data.4.2": "Pour en savoir plus sur notre utilisation des cookies, cliquez <a>ici</a>.",
  "privacy.purpose.title": "<n>02.</n> Quelles sont les finalités du traitement de vos données ?",
  "privacy.purpose.1": "Vos Données Personnelles sont destinées à et sont utilisées par URBAN CAMPUS pour :",
  "privacy.purpose.list": "<li>Créer, gérer et authentifier votre compte Utilisateur sur les Sites</li><li>Partager votre profil Utilisateur auprès des utilisateurs des Sites</li><li>Vous donner accès aux Services et fonctionnalités disponibles sur les Sites</li><li>Vous permettre de réserver et de payer vos espaces de coliving</li><li>Faciliter le retour des résidents et le changement de chambre pour les espaces de coliving</li><li>Vous envoyer des confirmations concernant les abonnements ou la réservation d’espaces de coliving</li><li>Vous accorder l'accès au bâtiment concerné par la réservation de l'espace de coliving</li><li>Gérer vos abonnements à nos Sites, vous envoyer des notifications relatives au paiement et assurer le suivi des factures</li><li>Vous envoyer des notifications et des rappels sur les étapes précédant et suivant votre emménagement dans votre nouveau studio de coliving</li><li>Vous envoyer des newsletter sur les services des Sites et sur les nouveautés d'URBAN CAMPUS, sauf si vous vous y opposez</li><li>Vous envoyer des messages commerciaux au nom d'URBAN CAMPUS ou de ses partenaires, si vous y avez préalablement consenti</li><li>Veiller au respect de la législation applicable, de nos Conditions Générales d’Utilisation et de notre Politique de Confidentialité</li><li>Vous informer des modifications apportées aux Sites ou aux Services que nous proposons</li><li>Réaliser des analyses et des statistiques sur les visites des Sites ou la réservation d'espaces de coliving</li><li>Adapter et améliorer nos Sites en fonction des terminaux et navigateurs que vous utilisez</li><li>Réaliser des analyses et des statistiques sur l'utilisation des espaces de coliving communs afin de les améliorer.</li>",
  "privacy.payment.title": "<n>03.</n> Comment vos données bancaires sont-elles collectées et traitées ?",
  "privacy.payment.1": "Afin d'assurer la sécurité des paiements que vous effectuez sur les Sites, URBAN CAMPUS fait appel à un prestataire de services, STRIPE, dont les pages de paiement utilisent le protocole TLS, certifié PCI-DSS. La norme PCI-DSS est une norme de sécurité internationale pour la protection de la confidentialité et de l'intégrité des données des titulaires de carte (données de carte et données de transaction). Nous ne conservons aucune information personnelle identifiante ni aucune information financière telle que les numéros de carte de crédit. Toutes ces informations sont fournies directement à notre partenaire tiers, STRIPE, dont l'utilisation de vos informations personnelles est régie par sa propre politique de confidentialité, qui peut être consultée sur <a>https://stripe.com/us/privacy</a>.",
  "privacy.payment.2": "Lorsque vous souscrivez à un abonnement payant sur les Sites par carte de crédit ou par débit direct (SEPA), le système de commande se connecte en temps réel au système STRIPE qui collecte les données bancaires que vous fournissez et les vérifie pour éviter les abus et les fraudes. URBAN CAMPUS ne conserve sur ses serveurs que le type, la date d'expiration et les 4 derniers chiffres de votre carte de crédit ou les 4 derniers chiffres de votre numéro SEPA ainsi que le mandat liant STRIPE à votre compte bancaire SEPA. Par ailleurs, les données complètes de votre carte de crédit et de votre SEPA sont stockées sur les serveurs de STRIPE et ne sont à aucun moment transmises aux serveurs des Sites. STRIPE demande l'autorisation à la banque et transmet à URBAN CAMPUS un numéro de transaction qui permet d'effectuer des transactions à hauteur du montant de l'autorisation.",
  "privacy.payment.3": "Vos données bancaires sont enregistrées afin d'éviter d'avoir à les saisir lors de vos prochains paiements. Elles sont enregistrées auprès de STRIPE de manière sécurisée. Vous pouvez consulter la liste de vos moyens de paiement enregistrés à tout moment en accédant à votre compte Utilisateur, et en supprimer un quand vous le souhaitez.",
  "privacy.recipient.title": "<n>04.</n> Qui sont les destinataires de vos données personnelles ?",
  "privacy.recipient.1": "Vos Données Personnelles sont destinées aux employés strictement habilités d'URBAN CAMPUS.  ",
  "privacy.recipient.2": "URBAN CAMPUS transmettra également vos Données Personnelles à ses prestataires techniques, agissant en son nom et pour son compte aux fins de l'exécution des Services proposés sur les Sites.",
  "privacy.recipient.3": "URBAN CAMPUS transmettra également vos Données Personnelles au propriétaire de l'immeuble dans lequel votre espace de coliving a été réservé. Ces données personnelles sont nécessaires pour le registre des contrats et le registre des dépôts de IVIMA.",
  "privacy.recipient.4": "D'autres utilisateurs des Sites peuvent également accéder aux informations indiquées dans votre profil. Par défaut, votre nom de famille et votre numéro d'appartement leur seront affichés.",
  "privacy.recipient.5": "URBAN CAMPUS peut partager vos Données Personnelles avec un tiers acquéreur des actifs de notre société.",
  "privacy.recipient.6": "Vos Données Personnelles pourront, le cas échéant, être communiquées aux autorités habilitées uniquement sur présentation d'une réquisition judiciaire ou administrative et dans les cas prévus par la loi applicable.",
  "privacy.cookies.title": "<n>05.</n> Cookies",
  "privacy.cookies.1": "URBAN CAMPUS utilise des cookies et des traceurs sur les Sites. Pour plus d'informations sur notre utilisation de ces technologies, veuillez consulter notre <a>Politique Cookies</a>.",
  "privacy.rights.title": "<n>06.</n> Quels sont vos droits ?",
  "privacy.rights.1": "Conformément à la législation en vigueur, vous disposez d'un droit d'accès, de rectification et de suppression de vos Données Personnelles ainsi que d'un droit à la limitation ou d'opposition à leur traitement. Vous pourrez également exercer votre droit à la portabilité pour obtenir une copie de vos Données Personnelles dans un format structuré, utilisable et lisible par machine, afin qu'elles puissent être transmises à un tiers, si cette demande est éligible.",
  "privacy.rights.2": "Pour l'exercice de ces droits, vous pouvez adresser vos demandes par courrier postal à l'adresse suivante : ONE THIRD, 16 rue Sainte-Apolline, 75002 Paris, France.",
  "privacy.rights.3": "Vous pouvez à tout moment accéder à votre compte Utilisateur sur les Sites pour modifier et mettre à jour vos Données Personnelles. Vous acceptez de nous fournir des informations complètes, exactes et à jour. Cependant, nous pouvons conserver certaines de vos Données Personnelles, lorsque la loi l'exige ou lorsque nous avons un motif légitime de le faire (par exemple, lorsque nous pensons que vous avez commis une fraude ou violé nos Conditions Générales d’Utilisation).",
  "privacy.rights.4": "Toute personne dont les Données Personnelles sont collectées peut définir des instructions générales ou spécifiques, concernant la conservation, l'effacement et la communication de ses données personnelles après son décès. Les instructions spécifiques peuvent être enregistrées auprès du responsable du traitement des données. Les directives générales peuvent être enregistrées auprès d'un tiers de confiance certifié par la CNIL. Les instructions générales ou spécifiques peuvent être modifiées ou supprimées à tout moment.",
  "privacy.rights.5": "Sauf si vous n'y avez pas consenti expressément lors de la création de votre compte Utilisateur ou si vous vous êtes désinscrit par la suite, vous pouvez choisir de ne plus recevoir de courriers électroniques promotionnels de notre part à tout moment en cliquant sur le lien de désinscription figurant en bas des courriers électroniques promotionnels que nous vous envoyons.",
  "privacy.duration.title": "<n>07.</n> Combien de temps sont conservées vos données personelles ?",
  "privacy.duration.1": "Vos Données Personnelles sont stockées sur les serveurs d'URBAN CAMPUS et conservées pour la durée strictement nécessaire à l'utilisation de votre compte et à l'exécution des Services que nous proposons sur les Sites.",
  "privacy.duration.2": "En particulier, vos Données Personnelles sont conservées :",
  "privacy.duration.list": "<li>3 ans après votre dernier contact avec URBAN CAMPUS</li><li>3 ans après la fin de votre dernière réservation d'un espace de coliving, dans le cas où vous avez souscrit à un abonnement payant aux Sites.</li>",
  "privacy.duration.3": "Les données collectées par les cookies sont conservées pour une durée maximale de 25 mois.",
  "privacy.duration.4": "Vos données bancaires seront supprimées une fois la transaction effectuée et archivées par STRIPE pendant une durée maximale de 13 mois à compter de la date de débit du compte à des fins de preuve, en cas de litige concernant le paiement.",
  "privacy.security.title": "<n>08.</n> Quelles mesures de sécurtité et de confidentialité sont mises en place ? ",
  "privacy.security.1": "URBAN CAMPUS met en œuvre toutes les mesures appropriées pour assurer la sécurité de vos données et pour réduire le risque de perte, de détérioration ou de mauvaise utilisation des données.",
  "privacy.security.2": "Le mot de passe que vous enregistrez lors de votre inscription vous permet de protéger votre compte Utilisateur contre tout accès non autorisé. Nous souhaitons attirer votre attention sur les mesures de précaution que vous devez prendre pour protéger la confidentialité de votre mot de passe et son utilisation. En particulier, nous vous conseillons vivement de choisir un mot de passe contenant au moins 12 caractères alphanumériques (combinaison de majuscules, minuscules, chiffres et caractères spéciaux) pour sécuriser votre authentification.",
  "privacy.security.3": "Vous disposez également de la possibilité, depuis les paramètres de votre compte Utilisateur, et à tout moment, de masquer aux autres utilisateurs des Sites les informations de votre profil.",
  "privacy.security.4": "Vous êtes responsable de toute utilisation des Sites et des services associés par toute personne utilisant votre identifiant de compte utilisateur et votre mot de passe. Vous devez nous contacter immédiatement si vous avez des raisons de croire que votre identifiant de compte Utilisateur et votre mot de passe ont été détournés par un tiers. Par mesure de précaution, nous vous conseillons de vous déconnecter de votre compte à la fin de chaque session.",
  "privacy.transfer.title": "<n>09.</n> Les données sont-elles transférées en dehors de l'Union Européennes ?",
  "privacy.transfer.1": "Vos Données Personnelles sont stockées sur nos serveurs ou sur les serveurs de notre prestataire technique, STRIPE, hébergés au sein de l'Union Européenne.",
  "privacy.transfer.2": "Vos Données Personnelles peuvent être transférées vers des serveurs situés en dehors de l'Espace Economique Européen (EEE) dont le niveau de protection n'est pas considéré comme adéquat par les autorités européennes. Le cas échéant, URBAN CAMPUS s'engage à encadrer ces transferts conformément à la législation applicable.",
  "privacy.other.title": "<n>10.</n> Quelles autres informations devez-vous connaître ?",
  "privacy.other.1": "URBAN CAMPUS se réserve le droit de modifier cette Politique de Confidentialité à tout moment. Nous vous encourageons à la consulter régulièrement. Néanmoins, en cas de modification substantielle, un message d'information vous sera adressé par courrier électronique.",
  "privacy.other.2": "Pour toute information complémentaire sur notre gestion de vos Données Personnelles, nous vous invitons à nous envoyer un courrier électronique à l'adresse suivante : <a>support@urbancampus.com</a>.",
  "cookies.title": "Consentement des cookies",
  "cookies.body": "En cliquant sur “Accepter“, vous consentez à l'utilisation de cookies. Nous utilisons les cookies à des fins d'analyse, pour vous offrir l'expérience la plus pertinente. <a>Lire plus</a>",
  "cookies.accept": "Accepter",
  "cookies.refuse": "Refuser",
  "share.title": "Partager mon profil Urban Campus",
  "share.text": "Voici mon profile Urban Campus !",
  "share.copied": "Lien copié",
  "manifesto.title": "La communauté est votre catalyseur",
  "manifesto.purpose.title": "Notre mission",
  "manifesto.purpose.1": "Rencontre, collaboration et création.",
  "manifesto.purpose.2": "Développez votre réseau.{linebreak}Collaborez.{linebreak}Rencontrez de nouvelles personnes.",
  "manifesto.values.title": "Nos valeurs",
  "manifesto.values.1.title": "Recontres",
  "manifesto.values.1": "Rencontrez de nouvelles personnes, faites-vous de nouveaux amis, trouvez du soutien, essayez de nouvelles choses.",
  "manifesto.values.2.title": "Implication",
  "manifesto.values.2": "Des ateliers interactifs, jusqu'aux barbecues, en passant par les cours de fitness et les projections de films, nous sommes là pour vous aider à tout mettre en place.",
  "manifesto.values.3.title": "Community first",
  "manifesto.values.3": "Inutile de dire que lorsque vous vivez et travaillez en groupe, ce que vous faites affecte tout le monde, ce qui rend le respect super important - nous sommes ici pour nous construire les uns les autres autant que nous-mêmes, et nous apprécions la diversité.",
  "manifesto.values.4.title": "Co-création",
  "manifesto.values.4": "Ecofriendly, promouvoir un mode de vie durable en travaillant ensemble pour réduire notre impact sur l'environnement et être conscient.",
  "manifesto.button": "Découvrir la communauté",
  "list.see.more": "Voir plus",
  "help.title": "Support",
  "help.subtitle": "Assurez-vous d’avoir consulter le <a>Urban Campus Guide</a>. Vous pouvez y trouver toute l’information dont vous avez besoin et comment résoudre les questions les plus courantes.",
  "help.form.title": "Nous contacter",
  "help.form.subjectLabel": "Titre",
  "help.form.subjectPlaceholder": "Votre demande en quelques mots",
  "help.form.contentLabel": "Message",
  "help.form.contentPlaceholder": "Décrivez votre demande avec le plus de détails possible, afin que nous puissions vous aider au mieux",
  "help.form.button": "Envoyer ma demande",
  "help.form.upload": "Ajouter un fichier à votre demande",
  "help.form.change": "Changer le fichier",
  "help.error": "Erreur pendant l'envoi de la demande",
  "help.success": "Merci! Nous avons enregistré votre demande. Notre équipe de Support vous répondra au plus vite.",
  "help.support": "Merci de contacter notre équipe Support directement par email : <a>support@urbancampus.com</a>.",
  "paymentError.title": "{count, plural, =0 {} one {Erreur} other {Erreurs}} de paiement",
  "paymentError.ERROR": "Votre paiement de {amount} {last4, select, undefined {} other {facturé sur votre <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} a échoué.",
  "paymentError.UNPAID": "Votre paiement de {amount} {last4, select, undefined {} other {facturé sur votre <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} a échoué.",
  "paymentError.REQUIRES_ACTION": "Votre paiement de {amount} {last4, select, undefined {} other {facturé sur votre <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} a échoué.",
  "paymentError.DISPUTED": "Votre paiement de {amount} {last4, select, undefined {} other {facturé sur votre <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} a été contesté par votre banque.",
  "paymentError.updatePm": "Merci de <a>mettre à jour votre méthode de paiement</a> pour que nous puissions essayer à nouveau.",
  "paymentError.disputed": "Veuillez contacter notre équipe d'assistance pour résoudre le problème. Chaque paiement contesté est penalisé par une charge supplémentaire de 50€.",
  "paymentError.PARTIALLY_PAID": "Votre paiement pour {month} n'a été effectué que partiellement : nous avons reçu {amountReceived} sur le total de {amount}.{linebreak}Veuillez procéder au paiement des {amountRemaining} restants.",
  "payment.retry": "Réessayer",
  "payment.retry.error": "Paiement échoué",
  "payment.retry.success": "Paiement réussi",
  "pending.modification.title": "Contrat en attente de signature",
  "pending.modification.sign.embedded": "Veuillez <a>signer vos contrats</a>, afin que votre date de départ soit désormais le {date}.",
  "pending.modification.cancel.embedded": "Vous ne voulez plus modifier votre date de départ ? <a>Annuler votre modification</a>.",
  "pending.modification.sign.not.embedded": "Veuillez signer le contrat que nous vous avons envoyé par e-mail, afin que votre date de départ soit désormais le {date}.",
  "pending.modification.cancel.not.embedded": "Vous ne voulez plus modifier votre date de départ ? <a>Contactez l'assistance</a>.",
  "maintenance.title": "Maintenance",
  "maintenance.page.title": "Maintenance en cours",
  "maintenance.page.body": "Cette page sera de retour dès que possible. Veuillez <a>contacter l'assistance</a> pour vous assurer d'être informer lorsque l'application sera de nouveau disponible.{linebreak}{linebreak}Veuillez nous excuser pour la gêne occasionnée.",
  "maintenance.page.reload": "Recharger la page",
  "extension.button": "Prolonger le séjour",
  "extension.modal.title": "Sélectionner une nouvelle date de départ",
  "termination.button.withEndDate": "Partir plus tôt",
  "termination.button.withoutEndDate": "Choisir la date de départ",
  "termination.modal.title": "Sélectionner une nouvelle date de départ"
}