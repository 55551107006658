{
  "member.footer.informations": "Encuentra toda la información que necesitas en la Urban Campus Guide",
  "member.footer.allRightsReserved": "(C) 2022 Todos los derechos reservados. Esta member area ha sido creada con ❤️ por <a>www.urbancampus.com</a>",
  "error.title": "No podemos cargar esta página.",
  "error.body": "Lo sentimos, hay un fallo por nuestra parte.{linebreak}Intenta volver a cargar la página si el problema persiste. <a>contacta support</a>.",
  "error.button": "Volver a la home",
  "error.message": "Parece que hay un problema.{linebreak}No te preocupes, no eres tú - somos nosotros. Lo sentimos.",
  "error.submessage": "Si el error persiste, contacta a <a>our Customer Support team</a> con el siguiente error:",
  "error.actionText": "Vuelve a cargar la página",
  "notFound.message": "No podemos encontrar la página que estás buscando,{linebreak}estás demasiado lejos de nosotros, ¡vuelve!",
  "notFound.action": "Página de inicio",
  "account.email.label": "Email",
  "account.password.label": "Contraseña",
  "account.passwordConfirm.label": "Contraseña (confirmar)",
  "resetPassword.form.resetTitle": "Restablece tu contraseña.",
  "resetPassword.form.createTitle": "Por favor establece tu contraseña para crear tu cuenta, con ella podrás acceder a tu member area de ahora en adelante.",
  "resetPassword.error.title": "Error",
  "resetPassword.error.message": "Ha ocurrido un error mientras guardabas tu nueva contraseña.",
  "resetPassword.error.passwordMatch": "Las contraseñas no coinciden",
  "resetPassword.success.message": "Tu contraseña ha sido guardada.",
  "resetPassword.reset.title": "Se ha establecido tu nueva contraseña.",
  "resetPassword.reset.back": "Volver al inicio de sesión",
  "resetPassword.button.save": "Guardar mi nueva contraseña",
  "stay.title": "Estancia",
  "rental.title": "Alquiler",
  "stay.movein": "Move-in: {date}",
  "stay.moveout": "Move-out: {date}",
  "stay.modification.tooLate": "El periodo de preaviso de 30 días para terminar tu suscripción ya ha pasado. Si quieres cambiar tu fecha de salida, por favor <a>ponte en contacto con el equipo de atención al cliente</a>.",
  "stay.modification.company": "Dado que tienes un contrato de empresa, por favor <a>contacta con atención al cliente</a> para modificar tu fecha de salida.",
  "modificationCreate.button.text": "Modificar la fecha de move-out",
  "modificationCreate.modal.title": "Nueva fecha de move-out",
  "modificationCreate.form.currentDate": "En este momento, tu estancia finaliza el {date}",
  "modificationCreate.form.minDate": "Puedes elegir una fecha desde {date} en adelante.",
  "modificationCreate.form.newEndDateLabel": "Nueva fecha de move-out",
  "modificationCreate.form.extensionDuration": "Esto corresponde a <strong>{years, plural, =0 {} one {# year} other {# years}}{hasYearMonthAnd, select, coma {, } and { and } other { }}{months, plural, =0 {} one {# month} other {# months}}{hasMonthDayAnd, select, and { and } other { }}{days, plural, =0 {} one {# dia} other {# dias}} extension</strong>.",
  "modificationCreate.form.terminationDuration": "Te irás <strong>{years, plural, =0 {} one {# year} other {# years}}{hasYearMonthAnd, select, coma {, } and { and } other { }}{months, plural, =0 {} one {# month} other {# months}}{hasMonthDayAnd, select, and { and } other { }}{days, plural, =0 {} one {# dia} other {# dias}}</strong> antes de lo previsto.",
  "modificationCreate.form.price": "<strong>{price}</strong> /mes",
  "modificationCreate.form.contactSupport": "<a>Contacta con Customer Support</a>",
  "modificationCreate.form.confirmButtonText": "Confirmar",
  "modificationCreate.form.thanks": "Gracias {name}!",
  "modificationCreate.form.newEndDate": "<strong>Ahora tu estancia finalizará el {date}.</strong>",
  "modificationCreate.form.coupleContracts": "Te llegarán los contratos al email. Por favor, fírmalos en cuanto los recibas. Tu pareja los recibirá y los deberá firmar también, por favor recuérdaselo.",
  "modificationCreate.form.coupleUpdate": "Una vez firmados, los cambios se aplicarán a tu suscripción.",
  "modificationCreate.form.closeButton": "Cerrar",
  "modificationCreate.reason.label": "¿Por qué?",
  "modificationCreate.details.label": "¿Algún detalle más?",
  "modificationCreate.form.newParkingEndDateLabel": "Nueva fecha de finalización del alquiler",
  "modificationCreate.parkingDetails.label": "¿Por qué quieres finalizar tu alquiler?",
  "modificationCreate.reason.MOVING_FROM_MADRID": "Me voy de {city}",
  "modificationCreate.reason.NEED_MORE_SPACE": "Necesito más espacio",
  "modificationCreate.reason.NEED_MORE_PRIVACY": "Necesito más privacidad",
  "modificationCreate.reason.FINANCIAL_CHANGED": "Mi situación financiera ha cambiado",
  "modificationCreate.reason.COMMUNITY_DONT_FIT": "No encajo en la comunidad",
  "modificationCreate.reason.NOT_ENJOYING_UC": "No estoy disfrutando en Urban Campus",
  "modificationCreate.reason.OTHER": "Otro (indica la razón debajo)",
  "modificationCreate.penalty.none": "No se aplicará penalización.",
  "modificationCreate.penalty.applied": "Compromiso incumplido: se aplicará una penalización.",
  "modificationCreate.contracts.waiting": "Esperando la firma...",
  "modificationCreate.contracts.button": "Firmar contratos",
  "modificationCreate.form.update": "El cambio será visible pronto.",
  "modificationCreate.error": "Ocurrió un error al cambiar la fecha de mudanza.",
  "modificationCancel.text": "¿Ya no quieres cambiar la fecha?",
  "modificationCancel.action": "<a>Cancelar tu modificación</a>",
  "modificationCreate.form.newEndDateError": "Selecciona una fecha válida",
  "onboarding.introduction.title": "¡Bienvenido a Urban Campus!",
  "onboarding.introduction.subtitle": "Crea tu perfil para desbloquear la experiencia completa de comunidad.",
  "onboarding.introduction.button": "¡Vamos!",
  "onboarding.profilePicture.title": "Sube tu foto",
  "onboarding.profilePicture.label": "Añade una foto",
  "onboarding.profilePicture.preview": "Preview:",
  "onboarding.profilePicture.error": "Error al subir la foto.",
  "onboarding.profilePicture.button": "Continuar",
  "onboarding.bio.title": "¡Deja que todo el mundo lo sepa!",
  "onboarding.bio.subtitle": "Usaremos esta información para generar tu bio.",
  "onboarding.bio.past": "¿Qué has estado haciendo durante los últimos 3-5 años?",
  "onboarding.bio.now": "¿A qué te dedicas ahora?",
  "onboarding.bio.passion": "¿Qué te apasiona (intereses/hobbies)?",
  "onboarding.bio.expectation": "¿Qué esperas obtener de la comunidad?",
  "onboarding.bio.country": "De",
  "onboarding.bio.nickname": "Nombre",
  "onboarding.bio.pronouns": "Pronombres",
  "onboarding.bio.customPronouns": "Pronombres",
  "onboarding.bio.pronouns.he": "Él",
  "onboarding.bio.pronouns.she": "Ella",
  "onboarding.bio.pronouns.they": "Elle",
  "onboarding.bio.pronouns.other": "Otro",
  "onboarding.bio.error": "Error al actualizar tu bio.",
  "onboarding.bio.button": "Continuar",
  "onboarding.bio.back": "Atrás",
  "onboarding.social.title": "Llévalo al siguiente nivel.",
  "onboarding.social.subtitle": "Cuéntanos cómo puede encontrarte la gente",
  "onboarding.social.languages": "Idiomas que hablas",
  "onboarding.social.numberUsedForWhatsapp": "Uso el {number} como número de Whatsapp",
  "onboarding.social.whatsapp": "Whatsapp",
  "onboarding.social.instagram": "Instagram",
  "onboarding.social.linkedin": "Linkedin",
  "onboarding.social.error": "Error al actualizar tu información de redes sociales.",
  "onboarding.social.button": "Continuar",
  "onboarding.social.back": "Atrás",
  "onboarding.community.title": "Crece con la comunidad",
  "onboarding.community.subtitle": "Conecta, participa y desarróllate.",
  "onboarding.community.grow": "Aumenta tu red",
  "onboarding.community.find": "Encuentra apoyo",
  "onboarding.community.meet": "Conoce gente nueva",
  "onboarding.community.body": "En UC encontrarás una mentalidad que te lleva a probar cosas nuevas, a involucrarte y a sentirte como en casa.",
  "onboarding.community.manifestoLink": "<a>Lea el manifiesto comunitario entero</a>",
  "onboarding.community.button": "Descubre la communidad",
  "forgot.toast.success": "Revisa tus emails",
  "forgot.layout.title": "¿Olvidaste la contraseña?",
  "forgot.layout.subtitle": "Indícanos tu dirección de email y te mandaremos un link para restablecer la contraseña.",
  "forgot.layout.sentTitle": "Restablecer enlace enviado",
  "forgot.sent.info": "Te hemos mandado el mail con el link para restrablecer la contraseña. Revisa tu bandeja de entrada y pulsa sobre el link \"restrablecer tu contraseña\".{linebreak}{linebreak} Si no recibes el email con el link para restablecer tu contraseña en los próximos 10 minutos, significa que el email que has indicado no se corresponde con el de tu perfil de residente. Por favor inténtalo con otro email.{linebreak}{linebreak}<i>Hint: El email que tenemos registrado debería ser el mismo que usaste para hacer tu booking.</i>{linebreak}{linebreak}<i></i>{linebreak}<i> Si no has recibido ningún email, por favor ponte en contacto con Customer Support.</i>",
  "forgot.sent.login": "<a>Volver al inicio de sesión</a>",
  "forgot.form.email": "Email",
  "forgot.form.button": "Mandar el link",
  "orderWarning.confirm.title": "¡Gracias!",
  "orderWarning.confirm.infos": "Gracias, tu pago se está procesando. Recibirás un email tanto si se procesa con éxito como falla.",
  "orderWarning.secure3ds.paymentFailed": "Tu último pago de {amount} que se cargó en tu {lastChargeKind} terminada en {lastChargeLast4} falló. Volveremos a hacer el cargo en tu {orderKind} terminada en {orderLast4} el {nextChargeDate}.",
  "orderWarning.secure3ds.bankSecurity": "Tu último pago no se pudo procesar automáticamente.{linebreak} Necesitas confirmar el pago usando una autenticación extra.{linebreak} Tu banco requiere esta medida de seguridad para tu tarjeta terminada en {orderLast4}.{linebreak} Para finalizar tu pago de {amount}, por favor haz click en el botón.",
  "orderWarning.secure3ds.contactUs": "Por favor visita <a1>this page</a1> o manda un email a <a2>our support team</a2>.",
  "orderWarning.disputed.period": "para el periodo {startDate to {endDate}",
  "orderWarning.disputed.disputed": "Tu último pago de {amount} {periodPart} no se pudo procesar automáticamente.{linebreak} El cargo se aceptó en un primer momento pero luego fue disputado por tu banco. Contacta <a>a nuestro Customer Support team</a> para finalizar el pago.",
  "orderWarning.error.update": "Actualiza tu método de pago para continuar disfrutando de nuestro espacio de Coliving.",
  "paymentMethod.form.title": "Actualizar mi método de pago",
  "modSignature.error.waiting": "<strong>Esperando algunas firmas de los contratos mandados por email </strong> antes del {issueDate}.{linebreak}Así te asegurarás de que tu estancia se extiende hasta el {newDate}.{linebreak} Para más información, revisa más abajo la sección de contratos.",
  "invoices.title": "Historial de facturación",
  "invoices.header.status": "Estado",
  "invoices.header.date": "Fecha",
  "invoices.header.amount": "Cantidad",
  "invoices.header.receipts": "Recibos",
  "invoices.header.invoices": "Facturas",
  "invoices.download.error": "Error: No se puede descargar la factura",
  "contracts.title": "Contratos",
  "contracts.header.status": "Estado",
  "contracts.header.date": "Fecha",
  "contracts.header.type": "Tipo",
  "contracts.header.contract": "Contrato",
  "contracts.type.RENTAL": "Alquiler",
  "contracts.type.RENTAL_EXTENSION": "Enmienda de alquiler",
  "contracts.type.RENTAL_EARLY_TERMINATION": "Enmienda de alquiler",
  "contracts.type.SERVICE": "Servicios",
  "contracts.type.SERVICE_EXTENSION": "Enmienda de servicios",
  "contracts.type.SERVICE_EARLY_TERMINATION": "Enmienda de servicios",
  "contracts.type.RENTAL_CANCELLATION": "Cancelación",
  "contracts.type.SERVICE_CANCELLATION": "Cancelacíon de servicios",
  "contracts.download.error": "Error: No se puede descargar el contrato",
  "login.toast.failed": "Inicio de sesión o contraseña incorrecta",
  "login.layout.title": "Bienvenido de nuevo",
  "login.form.email": "Email",
  "login.form.password": "Contraseña",
  "login.form.forgot": "Olvidé la contraseña",
  "login.form.button": "Inicio de sesión",
  "resident.details.from": "De {country}",
  "resident.stay.arriving": "¡Llegará en {days} días en {building} {flat}!",
  "resident.stay.alumni": "Alumni",
  "resident.stay.flat": "{building} - {flat}",
  "resident.languages": "Idiomas",
  "resident.socials": "Redes sociales",
  "resident.whatsapp": "Whatsapp",
  "resident.instagram": "Instagram",
  "resident.linkedin": "Linkedin",
  "resident.public": "La gente de la comunidad puede ver tu información",
  "resident.private": "Nadie de la comunidad puede encontrarte.",
  "resident.edit": "Editar perfil",
  "resident.save": "Guardar",
  "payOrder.link.expired": "El link ha caducado.",
  "resident.empty.title": "Rellena tu perfil",
  "resident.empty.subtitle": "Tu perfil está vacío, empieza y desbloquea la experiencia completa de comunidad.",
  "resident.empty.action": "Empezar",
  "resident.cta.action": "Editar",
  "resident.cta.bio": "<strong>Tu bio está vacía</strong>. ¡Actualiza tu perfil y comparte tu historia con la comunidad!",
  "resident.cta.languages": "<strong>Rellena los idiomas que hablas</strong>{linebreak}Si la gente lo sabe, ¡será más fácil para ellos empezar una conversación contigo!",
  "resident.cta.socials": "<strong>Instagram, Linkedin, Whatsapp</strong>{linebreak}Dile a la comunidad cómo puede ponerse en contacto contigo.",
  "resident.privatescope.info": "En cualquier momento, puede hacer que su perfil sea privado en la configuración.",
  "resident.bio.past": "¿Qué he estado haciendo durante los últimos 3-5 años?",
  "resident.bio.now": "¿A qué me dedico ahora?",
  "resident.bio.passion": "¿Qué me apasiona?",
  "resident.bio.expectation": "¿Qué espero obtener de la comunidad?",
  "resident.pronouns.He/Him": "Él",
  "resident.pronouns.She/Her": "Ella",
  "resident.pronouns.They/Them": "Elle",
  "resident.pronouns.Other": "Otros",
  "resident.flatmates.title": "En su piso",
  "resident.floormates.title": "En su planta",
  "subscriptions.title": "Mis suscripciones",
  "paymentMethod.preview.update": "Actualizar mi método de pago",
  "paymentMethod.preview.title": "Mi método de pago",
  "paymentMethod.preview.mandate": "<strong>Debitos SEPA:</strong> <elt>IBAN - {last4}</elt>",
  "toast.paymentMethod.updated": "¡Tu método de pago ha sido actualizado!",
  "paymentMethod.form.noIban": "No tengo número IBAN",
  "paymentMethod.form.name": "Nombre del titular",
  "paymentMethod.form.email": "Email",
  "paymentMethod.form.acceptance": "Si nos proporcionas tu IBAN y confirmas este pago, estás autorizando a Urban Campus y a Stripe, nuestro proveedor de servicios de pago, a mandar instrucciones a tu banco para hacer un cargo en tu cuenta de acuerdo a esas instrucciones. Tienes derecho a un reembolso por parte de tu banco según los términos y condiciones del acuerdo que tengas con él. El reembolso se debe reclamar en las 8 semanas siguientes a la fecha en la que se hizo el cargo a tu cuenta.",
  "paymentMethod.form.card": "Tengo número IBAN",
  "paymentMethod.form.cancel": "Cancelar",
  "paymentMethod.form.button": "Actualizar mi método de pago",
  "paymentOrder.confirmPayment": "Confirmar pago",
  "paymentOrder.form.cancel": "Cancelar y volver a la pantalla anterior",
  "paymentOrder.form.button": "Confirmar pago",
  "paymentOrder.newSource": "Cambiar mi método de pago",
  "paymentOrder.3ds": "Atrás",
  "community.title": "Comunidad",
  "community.subtitle": "La communidad Urban Campus tiene un objectivo: unir a la gente. <a>Lee nuestro manifiesto.</a>",
  "community.tab.current": "Actuales ({count})",
  "community.tab.upcoming": "Proximamente ({count})",
  "community.residents.showMore": "Mostrar más",
  "community.search.label": "Buscar miembros o apartamento",
  "community.campus.subtitle": "Coliving {current, plural, =0 {} one {• # miembro} other {• # miembros}} {upcoming, plural, =0 {} one {• # futuro miembro} other {• # futuros miembros}}",
  "community.flatmates.title": "En tu piso",
  "community.floormates.title": "En tu planta",
  "community.campus.title": "Campuses",
  "community.campus.button": "Ver comunidad",
  "community.head.title": "Gestora de la comunidad",
  "header.tabs.subscriptions": "Suscripciones",
  "header.tabs.community": "Comunidad",
  "menu.items.profile": "Perfil",
  "menu.items.settings": "Configuración",
  "menu.items.help": "Support",
  "menu.items.logout": "Cerrar sesión",
  "page.title.login": "Inicio de sesión",
  "page.title.home": "Suscripciones",
  "page.title.subscriptions": "Suscripciones",
  "page.title.subscription": "Suscripción",
  "page.title.forgotPassword": "Olvidé la contraseña",
  "page.title.resetPassword": "Restablecer contraseña",
  "page.title.pay": "Pagar",
  "page.title.profile": "Perfil",
  "page.title.resident": "Residente",
  "page.title.community": "Comunidad",
  "page.title.campus": "Comunidad - {campus}",
  "page.title.notFound": "404 - Not found",
  "page.title.settings": "Configuración",
  "page.title.privacyPolicy": "Política de Privacidad",
  "page.title.manifesto": "Manifiesto",
  "page.title.help": "Ayuda",
  "settings.title": "Configuración",
  "settings.changePassword.button": "Cambiar contraseña",
  "settings.changePassword.title": "Cambiar contraseña",
  "settings.ChangePassword.old": "Antigua contraseña",
  "settings.ChangePassword.password": "Nueva contraseña",
  "settings.ChangePassword.passwordConfirm": "Confirmar nueva contraseña",
  "settings.ChangePassword.save": "Guardar",
  "settings.ChangePassword.error": "Ocurrió un error mientras cambiabas tu contraseña.",
  "settings.form.phoneLabel": "Teléfono",
  "settings.form.emailLabel": "Email",
  "settings.form.scopeLabel": "Perfil público",
  "settings.form.languageLabel": "Idioma de preferencia",
  "settings.form.scopeCaption": "Al hacer tu perfil privado, desaparecerás de la lista de la comunidad, haciéndote invisible en la member area.",
  "settings.form.genderLabel": "Género",
  "settings.form.scope.PRIVATE": "No",
  "settings.form.scope.PUBLIC": "Sí",
  "settings.form.gender.0": "Sin género",
  "settings.form.gender.1": "Masculino",
  "settings.form.gender.2": "Femenino",
  "settings.form.gender.3": "Prefiero no decirlo",
  "settings.form.gender.4": "No binario",
  "settings.edit.button": "Editar",
  "settings.save.button": "Guardar",
  "settings.form.error": "Ocurrió un error mientras editabas tu configuración.",
  "language.en": "Inglés",
  "language.fr": "Francés",
  "language.es": "Español",
  "resident.edit.bioSubtitle": "Biografía",
  "resident.edit.essentialSubtitle": "General",
  "resident.edit.socialSubtitle": "Social",
  "paymentMethod.update": "Actualizar mi método de pago",
  "paymentMethod.retry": "Reintentar ahora",
  "paymentMethod.finalize": "Finalizar mi pago",
  "housekeeping.title": "Mantenimiento de la casa",
  "housekeeping.service.CLEAN": "Limpieza de los espacios privados",
  "housekeeping.service.LINEN": "Suministro y limpieza de ropa de cama y toallas",
  "housekeeping.CLEAN.body": "Obtenga la tranquilidad de no tener que preocuparse por la limpieza de su habitación.{linebreak}<a>Mas información y horario de limpieza</a>",
  "housekeeping.CLEAN.title.ONCE": "Una vez al mes",
  "housekeeping.CLEAN.title.TWICE": "Dos veces al mes",
  "housekeeping.CLEAN.title.EVERY": "Todas las semanas",
  "housekeeping.CLEAN.title.DEEP": "Limpieza profunda cada semana",
  "housekeeping.CLEAN.subtitle.DEEP": "Limpieza profunda de todo tu espacio: baño, dormitorio, cocina y sala de estar.",
  "housekeeping.CLEAN.subtitle.LIGHT": "También incluye una limpieza semanal de 10 minutos de entrada y salida de la sala de estar y la cocina.",
  "housekeeping.CLEAN.included": "Incluido",
  "housekeeping.LINEN.title.EVERY": "Todas las semanas",
  "housekeeping.LINEN.title.TWICE": "Dos veces al mes",
  "housekeeping.LINEN.title.OWN": "Utiliza las tuyas",
  "housekeeping.LINEN.included": "Gratis",
  "housekeeping.LINEN.information": "Proporcionamos la ropa de cama y las toallas, las limpiamos y te hacemos la cama dos veces al mes.",
  "housekeeping.service.current": "Opción actual:",
  "housekeeping.service.available": "Opciones disponibles:",
  "housekeeping.service.next": "Próxima opción:",
  "housekeeping.service.currentText": "{frequency} • {servicePrice}",
  "housekeeping.service.currentTextWithDate": "{frequency} • {servicePrice} • Suscrito hasta el {endDate}",
  "housekeeping.service.nextText": "{frequency} • {servicePrice} • A partir de la {startDate}",
  "housekeeping.service.nextReadyText": "{frequency} • {servicePrice} • Suscrito hasta el {endDate}",
  "housekeeping.service.change": "Cambiar de opción",
  "housekeeping.service.changeUpcoming": "Cambiar la próxima opción",
  "housekeeping.changes.apply": "Aplicar cambios",
  "cancel.sub.error": "Ocurrió un error mientras cancelar tu suscripcion",
  "create.sub.error": "Ocurrió un error mientras crear tu suscripcion",
  "extraServices.title": "Servicios extra en tu campus",
  "extra.service.fit_class": "Sesión de fitness al aire libre",
  "extra.service.yoga_class": "Sesión de yoga al aire libre",
  "extra.service.es_class": "Clase de español",
  "extra.service.en_class": " Clase de inglés",
  "extra.service.breakfast": "Caja de desayuno",
  "extra.service.fruit_vege_basket": "Caja de frutas y verduras",
  "extra.service.storage": "Trastero de almacenamiento",
  "extra.service.parking": "Plaza de aparcamiento",
  "extra.service.price": "{servicePrice}/mes",
  "extra.service.prices": "Desde {servicePrice}/mes",
  "extra.service.subscribed": "Suscrito",
  "extra.service.subscribe": "Suscribete",
  "extra.service.unsubscribe": "Unsuscribete",
  "week.days.monday": "lunes",
  "week.days.tuesday": "martes",
  "week.days.wednesday": "miércoles",
  "week.days.thursday": "jueves",
  "week.days.friday": "viernes",
  "week.days.saturday": "sábado",
  "week.days.sunday": "domingo",
  "extra.service.frequencyWeekly": "Todos los {day} a las {time} • {duration}h",
  "extra.service.subscribeAndStart": "Suscribete y empieza el próximo mes.",
  "extra.service.endDetails": "Estas suscrito hasta el {date}.",
  "extra.service.startDetails": "Tu suscripción empieza el {date}.",
  "extra.service.storage.body": "Para aquellos residentes que necesiten más espacio, tenemos unidades de almacenamiento disponibles para alquilar. Estas unidades ofrecen 4 metros cuadrados adicionales para que guardes tus artículos y pertenencias, en un área separada de tu espacio personal. Disponibilidad limitada y bajo petición.",
  "extra.service.storage.moreInfo": "<a>Más información</a>",
  "extra.service.storage.subscribe": "Si te interesa este servicio extra, o si quieres cancelarlo, por favor clica en el botón y nos pondremos en contacto contigo.",
  "extra.service.storage.callback": "Poneros en contacto conmigo",
  "extra.service.storage.success": "¡Gracias! Nos pondremos en contacto contigo pronto.",
  "extra.service.storage.error": "Ocurrió un error mientras guardabamos tu solicitud.",
  "extra.service.parking.body": "Mejora tu rutina diaria asegurando una plaza de aparcamiento conveniente con nuestras opciones de alquiler mensual.Despídete de la molestia de buscar aparcamiento a diario. Ofrecemos dos tipos de plazas de aparcamiento para adaptarse a tus necesidades:",
  "extra.service.parking.prices.parkspot": "• Plaza de aparcamiento exterior: {servicePrice} al mes",
  "extra.service.parking.prices.parkbox": "• Plaza de aparcamiento en garaje: {servicePrice} al mes",
  "extra.service.parking.subscribe": "Si te interesa este servicio extra, o si quieres cancelarlo, por favor clica en el botón y nos pondremos en contacto contigo.",
  "extra.service.parking.callback": "Poneros en contacto conmigo",
  "extra.service.parking.success": "¡Gracias! Nos pondremos en contacto contigo pronto.",
  "extra.service.parking.error": "Ocurrió un error mientras guardabamos tu solicitud.",
  "subscription.space.title": "{slug, select, roo {Habitación} std {Estudio} apt1b {Apartamento de 1 dormitorio} apt2b {Apartamento de 2 dormitorios} parkspot {Parking número} parkbox {Caja de estacionamiento número} other {}} {roomName}",
  "subscription.action.active": "Gestionar la suscripción",
  "subscription.state.NO_STATE": "Desconocido",
  "subscription.state.CREATED": "Creada",
  "subscription.state.CONTRACT_PENDING": "Contrato pendiente",
  "subscription.state.CONTRACT_SIGNED": "Contrato firmado",
  "subscription.state.DEPOSIT_PAID": "Depósito pagado",
  "subscription.state.DEPOSIT_ERROR": "Depósito en error",
  "subscription.state.REVIEWING": "Revisando",
  "subscription.state.REVIEW_FAILED": "Revision fallida",
  "subscription.state.READY": "Lista",
  "subscription.state.ACTIVE": "Activa",
  "subscription.state.PAST_DUE": "Vencida",
  "subscription.state.UNPAID": "No pagada",
  "subscription.state.CANCELING": "En curso de anulacíon",
  "subscription.state.PAUSED": "Pausada",
  "subscription.state.OVER": "Terminada",
  "subscription.state.DEPOSIT_REFUND": "Depósito reembolsado",
  "subscription.state.CANCELED": "Cancelada",
  "community.access.denied": "No tienen acceso a la comunidad las personas que dejaron el espacio hace más de un año o las personas que no terminaron su proceso de reserva. Si crees que deberías tener acceso a ella, ponte en contacto con customer support.",
  "payment.title": "Pagos",
  "payment.rent.title": "Renta pincipal",
  "payment.service.title": "Servicios y servicios extra",
  "payment.multiple.provider": "Los servicios y servicios extra se cobrarán aparte de su factura principal. Verá 2 transacciones en su cuenta bancaria.",
  "payment.multiple.explanation": "Estamos legalmente obligados a solicitarle una segunda vez un método de pago, ya que estos servicios adicionales se cobrarán aparte de su factura principal. Verá 2 transacciones en su cuenta bancaria y 2 facturas diferentes en su área de miembros.",
  "payment.card.number": "<strong>**** **** **** {last4}</strong> - {date}",
  "payment.bankTransfer.title": "Transferencia bancaria",
  "payment.bankTransfer.comment": "Este IBAN es único y no debe compartirse.",
  "payment.bankTransfer.acccountHolder": "Titular de la cuenta",
  "payment.bankTransfer.bic": "Código BIC",
  "payment.bankTransfer.iban": "IBAN",
  "payment.bankTransfer.country": "País",
  "payment.bankTransfer.support": "<a>Comuníquese con soporte para cambiar el método de pago</a>",
  "payment.modal.title": "Método de pago",
  "payment.modal.serviceTitle": "Método de pago par servicios extra",
  "payment.form.name": "Nombre",
  "payment.form.error": "Método de pago rechazado",
  "payment.form.success": "Método de pago actualizado",
  "payment.preview.update": "Cambiar",
  "payment.form.button": "Actualizar payment method",
  "payment.form.switch": "{kind, select, SEPA {No tengo número IBAN, <a>rellena una tarjeta de crédito</a>} CC {No tengo una tarjeta de crédito, <a>rellena un número IBAN</a>} other {}}",
  "payment.form.acceptance": "Si nos proporcionas tu IBAN y confirmas este pago, estás autorizando a Urban Campus y a Stripe, nuestro proveedor de servicios de pago, a mandar instrucciones a tu banco para hacer un cargo en tu cuenta de acuerdo a esas instrucciones. Tienes derecho a un reembolso por parte de tu banco según los términos y condiciones del acuerdo que tengas con él. El reembolso se debe reclamar en las 8 semanas siguientes a la fecha en la que se hizo el cargo a tu cuenta.",
  "payment.next.title": "Próximo pago:",
  "payment.next.errorTitle": "Error de pago",
  "payment.next.subtitle": "{amount} • {date}",
  "payment.next.errorSubtitle": "{amount} {last4, select, undefined {} other {• {kind} - {last4}}}",
  "payment.partiallyPaid.title": "Pago de {month}",
  "payment.partiallyPaid.subtitle": "Total restante:  {amountRemaining}",
  "payment.partiallyPaid.contactSupport": "Contacta con support",
  "privacy.title": "Política de Privacidad",
  "privacy.lastUpdate": "Última actualización: {date}",
  "privacy.intro.1": "ONE THIRD (con domicilio social en 16 rue Sainte-Apolline, 75002 París, Francia, inscrita en el Registro Mercantil de París con el número 824582480), que opera con el nombre de URBAN CAMPUS (“ONE THIRD“, “URBAN CAMPUS“, “nosotros“ y “nuestro“) gestiona este sitio web: booking.urbancampus.com y member.urbancampus.com/ (en lo sucesivo, los “Sitios“). Los Sitios son accesibles para cualquier usuario (“Usuario“ o “Usted“).",
  "privacy.intro.2": "Los Sitios permiten a los Usuarios reservar espacios de convivencia (en adelante, coliving) dentro de las residencias gestionadas por Urban Campus y seleccionar los servicios relacionados (los “Servicios“).",
  "privacy.intro.3": "Con el fin de mejorar su experiencia de navegación y el rendimiento de sus servicios, URBAN CAMPUS requiere la recopilación de algunos de sus datos personales. URBAN CAMPUS, como responsable del tratamiento, concede gran importancia a la protección y el respeto de su privacidad.",
  "privacy.intro.4": "Esta Política de Privacidad, así como nuestros <a1>Términos y Condiciones</a1> y la <a2>Política de Cookies</a2> se aplican a la navegación por los Sitios y a los Servicios adquiridos por el Usuario. Esta Política de Privacidad le ofrece detalles sobre la forma y los fines para los que recogemos y utilizamos la información que nos proporciona y que recopilamos a lo largo del proceso.",
  "privacy.intro.5": "Los Datos Personales recogidos serán tratados de acuerdo con lo dispuesto en la normativa aplicable en materia de protección de datos personales. Le invitamos a leer atentamente esta Política de Privacidad.",
  "privacy.data.title": "<n>01.</n> ¿Qué datos se recogen y utilizan?",
  "privacy.data.intro.1": "Al utilizar nuestro Sitio y nuestros Servicios, se le pide que nos proporcione información, parte de la cual puede identificarle directa o indirectamente (“Datos personales“), al crear una cuenta de usuario, adquirir una suscripción de pago o reservar un espacio de coliving en los Sitios.",
  "privacy.data.intro.2": "Los Datos Personales que recogemos son los siguientes:",
  "privacy.data.1.title": "<n>1.1</n> Datos introducidos al crear su cuenta de Usuario",
  "privacy.data.1.1": "Para utilizar nuestros Servicios, debe crear una cuenta de Usuario introduciendo sus datos personales. Esta información se transfiere a nuestra herramienta CRM y se limita a los siguientes datos:",
  "privacy.data.1.list.1": "<li>Su nombre</li><li>Sus apellidos</li><li>Su dirección de correo electrónico</li><li>Su contraseña</li><li>Su fecha de nacimiento</li><li>Su número de teléfono</li><li>Su dirección</li>",
  "privacy.data.1.2": "Al crear/completar su perfil de Usuario, usted puede, de manera opcional, proporcionar la siguiente información:",
  "privacy.data.1.list.2": "<li>Su idioma preferido para la comunicación por correo electrónico</li><li>Su trabajo</li><li>Sus intereses</li><li>Su país de origen</li><li>Los pronombres con los que te identificas</li><li>Su foto de perfil, biografía, Instagram, Linkedin, WhatsApp</li>",
  "privacy.data.2.title": "<n>1.2</n> Datos introducidos al suscribirse",
  "privacy.data.2.1": "Cuando se suscribe a nuestros Servicios de coliving (o relacionados), los datos de pago que proporciona son recogidos y utilizados por nuestro proveedor de servicios, STRIPE, que utiliza una plataforma de pago en línea segura (PCI-DSS).",
  "privacy.data.2.2": "Para más información sobre el uso de sus datos bancarios, puede leer el apartado nº 3 “¿Cómo se recogen y gestionan sus datos de pago?“ de esta Política de Privacidad.",
  "privacy.data.3.title": "<n>1.3</n> Datos recogidos al utilizar nuestros servicios",
  "privacy.data.3.1": "Cuando usted reserva un espacio de coliving a través de los Sitios o utiliza nuestros Servicios, recopilamos y utilizamos Datos Personales que le conciernen directa o indirectamente, tales como:",
  "privacy.data.3.list": "<li>El ID de cuenta único asignado al reservar su espacio coliving</li><li>La información sobre su cuenta y su perfil</li><li>Los documentos legales solicitados durante el registro de su cuenta</li><li>El nombre de su garante y su salario anual</li><li>La información relacionada con su reserva (habitación, servicios, duración, cambios, contratos de sus estancias anteriores)</li>  <li>Los datos de pago (pago utilizado, importe, fecha de pago, número de factura);</li>",
  "privacy.data.4.title": "<n>1.4</n> Datos recogidos por las cookies ",
  "privacy.data.4.1": "Recopilamos los siguientes datos de navegación a través de las cookies de nuestros Sitios:",
  "privacy.data.4.list": "<li>Su dirección IP</li><li>El tipo y la versión del navegador que utiliza</li><li>El tipo de dispositivo con el que navega por los Sitios</li><li>Las redes desde las que se conecta a los Sitios</li><li>Las páginas de nuestro Sitio que usted visita</li><li>El contenido que usted ve en los Sitios</li><li>El tiempo empleado durante cada visita a las páginas y contenidos de los Sitios</li><li>El idioma en el que visita nuestros Sitios web</li><li>La ubicación (país) desde la que visita nuestros Sitios</li>",
  "privacy.data.4.2": "Para saber más sobre nuestro uso de las cookies, haga clic <a>aqui</a>.",
  "privacy.purpose.title": "<n>02.</n> ¿Cuáles son las finalidades del tratamiento de sus datos?",
  "privacy.purpose.1": "Sus datos personales están destinados y son utilizados por URBAN CAMPUS para:",
  "privacy.purpose.list": "<li>Crear, gestionar y autentificar su cuenta de Usuario en los Sitios</li><li>Compartir su perfil de usuario con los usuarios de los Sitios</li><li>Darle acceso a los Servicios y funcionalidades disponibles en nuestro Sitio</li><li>Permitirle reservar y pagar por espacios de coliving</li><li>Facilitar el reingreso de los residentes y el cambio de habitación para los espacios coliving</li><li>Enviarle las confirmaciones relativas a las suscripciones o a la reserva de los espacios coliving</li><li>Concederle acceso al edificio en cuestión por la reserva del espacio coliving</li><li>Gestionar sus suscripciones a nuestros Sitios, enviarle notificaciones relacionadas con el pago y hacer un seguimiento de las facturas</li><li>Enviarle notificaciones y recordatorios sobre los pasos previos y posteriores a su mudanza a su nuevo estudio coliving</li><li>El envío de boletines informativos sobre los servicios de los Sitios y las novedades de URBAN CAMPUS, a menos que usted se oponga a ello</li><li>Enviarle mensajes comerciales en nombre de URBAN CAMPUS o de sus socios, si previamente ha dado su consentimiento para ello</li><li>Garantizar el cumplimiento de la legislación aplicable, nuestras Condiciones Generales y nuestra Política de Privacidad</li><li>Informarle de los cambios en los Sitios o en los Servicios que ofrecemos</li><li>Realizar análisis y estadísticas de las visitas a los Sitios o de la reserva de espacios coliving</li><li>Adaptar y mejorar nuestros Sitios en función de los dispositivos y navegadores que usted utiliza.</li><li>Realizar análisis y estadísticas sobre el uso de las zonas comunes para mejorar nuestros espacios.</li>",
  "privacy.payment.title": "<n>03.</n> ¿Cómo se recogen y gestionan sus datos de pago?",
  "privacy.payment.1": "Para garantizar la seguridad de los pagos que realiza a través de los Sitios, URBAN CAMPUS utiliza un proveedor de servicios, STRIPE, cuyas páginas de pago utilizan el protocolo TLS, y certificado PCI-DSS. La norma PCI-DSS es una norma de seguridad internacional para la protección de la confidencialidad e integridad de los datos de los titulares de las tarjetas (datos de las tarjetas y de las transacciones). No conservamos ninguna información personal identificable ni ninguna información financiera, como los números de las tarjetas de crédito. Por el contrario, toda esa información se proporciona directamente a nuestro socio externo, Stripe, cuyo uso de su información personal se rige por su propia política de privacidad, que puede consultarse en <a>https://stripe.com/us/privacy</a>.",
  "privacy.payment.2": "Cuando usted adquiere una suscripción de pago en los Sitios mediante tarjeta de crédito o mediante domiciliación bancaria (SEPA), el sistema de pedidos se conecta en tiempo real con el sistema STRIPE que recoge los datos bancarios que usted proporciona y los comprueba para evitar abusos y fraudes. URBAN CAMPUS sólo conserva en sus servidores el tipo, la fecha de caducidad y los 4 últimos dígitos de su tarjeta de crédito o los 4 últimos dígitos de su número SEPA, así como el mandato que vincula a Stripe con su cuenta bancaria SEPA. Además, los datos completos de su tarjeta de crédito y SEPA se almacenan en los servidores de STRIPE y no se transmiten en ningún momento a los servidores de los Sitios. STRIPE solicita la autorización al banco y transmite a URBAN CAMPUS un número de transacción que permite realizar operaciones hasta el importe de la autorización.",
  "privacy.payment.3": "Sus datos bancarios se guardan para evitar tener que introducirlos en sus próximos pagos. Se registran en STRIPE de forma segura. En cualquier momento, accediendo a su cuenta de usuario, puede ver la lista de sus medios de pago registrados, y eliminar cualquiera de ellos cuando lo desee.",
  "privacy.recipient.title": "<n>04.</n> ¿Quiénes son los destinatarios de sus datos personales?",
  "privacy.recipient.1": "Sus Datos Personales están destinados estrictamente a los empleados autorizados de URBAN CAMPUS.",
  "privacy.recipient.2": "URBAN CAMPUS también transmitirá sus Datos Personales a sus proveedores de servicios técnicos, que actúan en su nombre y por su cuenta con el fin de realizar los Servicios ofrecidos en los Sitios.",
  "privacy.recipient.3": "URBAN CAMPUS también transmitirá sus Datos Personales al propietario del edificio en el que se ha reservado su espacio coliving. Estos Datos Personales son necesarios para el registro de contratos y depósitos en el IVIMA.",
  "privacy.recipient.4": "Otros usuarios de los Sitios también pueden tener acceso a la información de su perfil. Por defecto, se les mostrará su nombre y número de apartamento.",
  "privacy.recipient.5": "URBAN CAMPUS podrá compartir sus Datos Personales con un tercero comprador de los activos de nuestra empresa.",
  "privacy.recipient.6": "Sus Datos Personales podrán, en su caso, ser comunicados a las autoridades facultadas únicamente previa presentación de un requerimiento judicial o administrativo y en los casos previstos por la legislación aplicable.",
  "privacy.cookies.title": "<n>05.</n> Cookies",
  "privacy.cookies.1": "URBAN CAMPUS utiliza cookies y rastreadores en los Sitios. Para más información sobre nuestro uso de estas tecnologías, consulte nuestra <a>Política de Cookies</a>.",
  "privacy.rights.title": "<n>06.</n> ¿Cuáles son sus derechos?",
  "privacy.rights.1": "De acuerdo con la legislación vigente, usted tiene derecho a acceder, modificar o suprimir sus Datos Personales, así como a limitar su tratamiento u oponerse a él. También podrá ejercer su derecho a la portabilidad para obtener una copia de sus Datos Personales en un formato estructurado, utilizable y legible por máquina, a fin de que puedan ser transmitidos a un tercero, si dicha solicitud es admisible.",
  "privacy.rights.2": "Para el ejercicio de estos derechos, puede enviar sus solicitudes por correo postal a la siguiente dirección ONE THIRD, 16 rue Sainte-Apolline, 75002 París, Francia.",
  "privacy.rights.3": "Usted puede acceder en cualquier momento a su cuenta de usuario en los Sitios para modificar y actualizar sus Datos Personales. Usted se compromete a proporcionarnos información completa, precisa y actualizada. Sin embargo, podemos conservar algunos de sus Datos Personales, cuando lo exija la ley o cuando tengamos una razón legítima para hacerlo (por ejemplo, cuando creamos que usted ha cometido un fraude o que ha violado nuestros Términos y Condiciones).",
  "privacy.rights.4": "Cualquier persona cuyos datos personales se recojan podrá establecer instrucciones generales o específicas, relativas a la conservación, supresión y comunicación de sus datos personales tras su fallecimiento. Las instrucciones específicas pueden registrarse ante el responsable del tratamiento o ante un tercero de confianza certificado por la CNIL. Asimismo, las instrucciones generales o específicas pueden modificarse o suprimirse en cualquier momento.",
  "privacy.rights.5": "A menos que no lo haya consentido expresamente al crear su cuenta de Usuario o si se ha dado de baja posteriormente, puede optar por no recibir nuestros correos electrónicos promocionales en cualquier momento haciendo clic en el enlace para darse de baja que aparece en la parte inferior de los correos electrónicos promocionales que le enviamos.",
  "privacy.duration.title": "<n>07.</n> ¿Cuánto de tiempo se conservan sus datos personales?",
  "privacy.duration.1": "Sus Datos Personales se almacenan en los servidores de URBAN CAMPUS y se conservan durante el tiempo estrictamente necesario para los fines mencionados.",
  "privacy.duration.2": "En particular, sus Datos Personales se conservan:",
  "privacy.duration.list": "<li>3 años después de su último contacto con URBAN CAMPUS</li><li>3 años después de la finalización de su última reserva de un espacio de coliving, en caso de que haya adquirido una suscripción de pago al Sitio</li>",
  "privacy.duration.3": "Los datos recogidos por las cookies se conservan durante un período máximo de 25 meses.",
  "privacy.duration.4": "Sus datos bancarios se eliminarán una vez finalizada la transacción y serán archivados por Stripe durante un máximo de 13 meses a partir de la fecha de cargo en la cuenta a efectos probatorios, en caso de litigio sobre el pago.",
  "privacy.security.title": "<n>08.</n> ¿Qué medidas de confidencialidad y seguridad de losdatos existen?",
  "privacy.security.1": "URBAN CAMPUS aplica todas las medidas adecuadas para garantizar la seguridad de sus datos y reducir el riesgo de pérdida, deterioro o uso indebido de los mismos.",
  "privacy.security.2": "La contraseña que guarda al registrarse le permite proteger su cuenta de usuario contra el acceso no autorizado. Nos gustaría llamar su atención sobre las medidas de precaución que debe tomar para proteger la confidencialidad de su contraseña y su uso. En particular, le aconsejamos encarecidamente que elija una contraseña que contenga al menos 12 caracteres alfanuméricos (combinación de mayúsculas, minúsculas, cifras y caracteres especiales) para asegurar su autentificación.",
  "privacy.security.3": "También tiene la posibilidad de ocultar la información de su perfil a otros usuarios de los Sitios, desde la configuración de su cuenta de usuario, y en cualquier momento.",
  "privacy.security.4": "Usted es responsable de cualquier uso de nuestro Sitio y de los Servicios asociados por parte de cualquier persona que utilice su ID de cuenta de usuario y su contraseña. Debe ponerse en contacto con nosotros inmediatamente si tiene razones para creer que su ID de cuenta de usuario y su contraseña han sido malversados por un tercero. Como medida de precaución, le aconsejamos que cierre su cuenta al final de cada sesión.",
  "privacy.transfer.title": "<n>09.</n> ¿Se transfieren los datos fuera de la Unión Europea?",
  "privacy.transfer.1": "Sus Datos Personales se almacenan en nuestros servidores o en los de nuestro proveedor de servicios técnicos, alojados en la Unión Europea.",
  "privacy.transfer.2": "Sus Datos Personales pueden ser transferidos a servidores situados fuera del Espacio Económico Europeo (EEE) cuyo nivel de protección no es considerado adecuado por las autoridades europeas. En su caso, URBAN CAMPUS se compromete a regular dichas transferencias de acuerdo con la legislación aplicable.",
  "privacy.other.title": "<n>10.</n> ¿Qué otra información necesita saber?",
  "privacy.other.1": "URBAN CAMPUS se reserva el derecho a modificar esta Política de Privacidad en cualquier momento. Le animamos a que la consulte regularmente. No obstante, en caso de modificación sustancial, se le enviará un mensaje informativo por correo electrónico.",
  "privacy.other.2": "Si desea más información sobre nuestra gestión de sus Datos Personales, envíenos un correo electrónico a: <a>support@urbancampus.com</a>",
  "cookies.title": "Política de cookies",
  "cookies.body": "Si haces click en “Aceptar“, consientes que nuestra web haga uso de cookies. Usamos las cookies con fines analíticos, para darte una mejor experiencia. <a>Leer mas</a>",
  "cookies.accept": "Aceptar",
  "cookies.refuse": "Rechazar",
  "share.title": "Comparte mi perfil de Urban Campus",
  "share.text": "Aquí esta mi perfil Urban Campus!",
  "share.copied": "Enlace copiado en el portapapeles",
  "manifesto.title": "Crece con la comunidad",
  "manifesto.purpose.title": "Nuestro propósito",
  "manifesto.purpose.1": "Conecta, participa y desarróllate.",
  "manifesto.purpose.2": "Aumenta tu red.{linebreak}Encuentra apoyo.{linebreak}Conoce gente nueva.",
  "manifesto.values.title": "Nuestros valores",
  "manifesto.values.1.title": "Conecta",
  "manifesto.values.1": "Conoce a gente nueva, haz nuevos amigos, encuentra apoyo, vive nuevas experiencias.",
  "manifesto.values.2.title": "Involúcrate",
  "manifesto.values.2": "Desde talleres interactivos a barbacoas, clases de fitness y sesiones de cine, estamos aquí para ayudarte a organizar lo que tengas en mente.",
  "manifesto.values.3.title": "La Comunidad, lo primero",
  "manifesto.values.3": "No hace falta decir que cuando convives y trabajas con más gente, lo que tú hagas tiene un impacto sobre los demás. Por eso, el respeto es fundamental. Estamos aquí para apoyarnos los unos a los otros y apreciamos la diversidad.",
  "manifesto.values.4.title": "Creamos juntos",
  "manifesto.values.4": "Ecofriendly, promovemos una forma de vida sostenible trabajando juntos para reducir nuestro impacto en el medioambiente.",
  "manifesto.button": "Descubre la comunidad",
  "list.see.more": "Ver más",
  "help.title": "Support",
  "help.subtitle": "Antes de todo, asegúrate de echar un vistazo a la <a>Urban Campus Guide</a>. Allí podrás encontrar toda la información que necesitas para sacar el máximo partido a tu experiencia y cómo resolver las dudas más habituales.",
  "help.form.title": "Contáctanos",
  "help.form.subjectLabel": "Título",
  "help.form.subjectPlaceholder": "Tu solicitud en unas palabras",
  "help.form.contentLabel": "Mensaje",
  "help.form.contentPlaceholder": "Describe tu solicitud con tantos detalles como sea posible, para que te podamos ayudar y hacértelo un poquito más fácil",
  "help.form.button": "Enviar solicitud",
  "help.form.upload": "Agrega un archivo a su solicitud",
  "help.form.change": "Cambiar archivo",
  "help.error": "Ocurrió un error al enviar su solicitud",
  "help.success": "¡Gracias! Hemos registrado tu solicitud. El equipo de Support contactará contigo lo antes posible.",
  "help.support": "Por favor envíenos un correo electrónico a <a>support@urbancampus.com</a>.",
  "paymentError.title": "{count, plural, =0 {} one {Error} other {Errores}} de pago",
  "paymentError.ERROR": "Su pago de {amount} {last4, select, undefined {} other {con cargo a su <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} ha fallado.",
  "paymentError.REQUIRES_ACTION": "Su pago de {amount} {last4, select, undefined {} other {con cargo a su <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} ha fallado.",
  "paymentError.UNPAID": "Su pago de {amount} {last4, select, undefined {} other {con cargo a su <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} ha fallado.",
  "paymentError.DISPUTED": "Su pago de {amount} {last4, select, undefined {} other {con cargo a su <strong>{kind, select, IBAN {{kind} ***********************} other {{kind} **** **** **** }}{last4}</strong>}} fue disputado por su banco.",
  "paymentError.updatePm": "<a>Actualice su método de pago</a> para que podamos volver a intentarlo.",
  "paymentError.disputed": "Póngase en contacto con nuestro equipo de soporte para resolver el problema. Cada pago disputada se penaliza con un cargo extra de 50€.",
  "paymentError.PARTIALLY_PAID": "Su pago de {month} se realizó solo parcialmente: recibimos {amountReceived} del total {amount}.{linebreak}Por favor, continúe con el pago del {amountRemaining} restante.",
  "payment.retry": "Reintentar pago",
  "payment.retry.error": "Pago fallido",
  "payment.retry.success": "Pago exitoso",
  "pending.modification.title": "Contrato pendiente de firma",
  "pending.modification.sign.embedded": "Por favor, <a>firme sus contratos</a>, para que su fecha de finalización se cambie a {date}.",
  "pending.modification.cancel.embedded": "¿Ya no quieres modificar tu fecha de finalización? <a>Cancelar su modificación</a>.",
  "pending.modification.sign.not.embedded": "Por favor, firme el contrato que le enviamos por correo electrónico, para que su fecha de finalización se cambie a {date}.",
  "pending.modification.cancel.not.embedded": "¿Ya no quieres modificar tu fecha de finalización? <a>Comunícate con soporte</a>.",
  "maintenance.title": "Mantenimiento",
  "maintenance.page.title": "Mantenimiento en curso",
  "maintenance.page.body": "Esta página volverá a estar disponible lo antes posible. <a>Comunícate con soporte</a> para asegurarse de que le avise cuando la aplicación vuelva a funcionar.{linebreak}{linebreak}Lo siento por las molestias.",
  "maintenance.page.reload": "Recargar página",
  "extension.button": "Prolongar estancia",
  "extension.modal.title": "Elige una nueva fecha de finalización",
  "termination.button.withEndDate": "Mudarse antes",
  "termination.button.withoutEndDate": "Elige la fecha de mudanza",
  "termination.modal.title": "Elige una nueva fecha de finalización"
}